import { Navigate, Route, Routes } from 'react-router-dom'

import { ProtectedRoute } from './ProtectedRoute'

import useScrollToTop from 'hooks/useScrollToTop'
import { E_Routes } from 'models/routes'
import * as Pages from 'pages'

export const Routing = () => {
  useScrollToTop()
  return (
    <Routes>
      <Route element={<ProtectedRoute />}>
        <Route path={E_Routes.ngos} element={<Pages.FundsPage />} />
        <Route path={E_Routes.ngosNew} element={<Pages.FundsNewPage />} />
        <Route path={E_Routes.ngo} element={<Pages.FundPage />} />
        <Route path={E_Routes.fundraiser} element={<Pages.FundraiserPage />} />
        <Route path={E_Routes.fundraiserNew} element={<Pages.FundraiserNewPage />} />
        <Route path={E_Routes.reportClose} element={<Pages.ReportClosePage />} />
        <Route path={E_Routes.report} element={<Pages.ReportPage />} />
        <Route path={E_Routes.complaintsReports} element={<Pages.ComplaintsReportsPage />} />
        <Route
          path={E_Routes.complaintsFundraisers}
          element={<Pages.ComplaintsFundraisersPage />}
        />
        <Route path={E_Routes.dataReports} element={<Pages.DataReportsPage />} />
        <Route path={E_Routes.dataFundraisers} element={<Pages.DataFundraiserPage />} />
        <Route path={E_Routes.stats} element={<Pages.StatsPage />} />
        <Route path={E_Routes.notifications} element={<Pages.NotificationsPage />} />
        <Route path={E_Routes.refund} element={<Pages.RefundPage />} />
      </Route>
      <Route path='*' element={<Navigate to={E_Routes.ngos} />} />
    </Routes>
  )
}
