import { Alert, Button, Descriptions, Modal, Tag } from 'antd'
import { useState } from 'react'
import { Link } from 'react-router-dom'

import { isDuplicateFound } from './utils'

import { I_CampaignCheckResult, I_FundCheckResult, T_CampaignCheckItemResult } from 'models/check'
import { T_FundInternalId } from 'models/fund'

interface CheckFundResultProps {
  result: I_FundCheckResult
}

export const CheckFundResult = ({ result }: CheckFundResultProps) => {
  const [isModalVisible, setIsModalVisible] = useState(false)

  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  if (isDuplicateFound(result)) {
    return (
      <>
        <Alert
          message='Warning'
          showIcon
          description='Duplicate data found'
          type='warning'
          closable
          action={
            <Button size='small' type='primary' onClick={showModal}>
              Detail
            </Button>
          }
        />
        <Modal
          title='Duplicates'
          width={600}
          visible={isModalVisible}
          footer={null}
          onCancel={handleCancel}
        >
          <Descriptions bordered labelStyle={{ width: 175 }} column={1}>
            <Descriptions.Item label='General'>
              <div>Name: {mapFundResult(result.fundName)}</div>
              <div>Address: {mapFundResult(result.address)}</div>
              <div>Email: {mapFundResult(result.email)}</div>
              <div>Phone: {mapFundResult(result.phone)}</div>
              <div>Website: {mapFundResult(result.generalWebsite)}</div>
            </Descriptions.Item>
            <Descriptions.Item label='Legal Info'>
              <div>IT PAN: {mapFundResult(result.itPan)}</div>
              <div>Registration certificate: {mapFundResult(result.registrationNo)}</div>
              <div>Address: {mapFundResult(result.legalAddress)}</div>
            </Descriptions.Item>
            <Descriptions.Item label='Fund manager'>
              <div>Email: {mapFundResult(result.headPersonEmail)}</div>
              <div>Name: {mapFundResult(result.headPersonName)}</div>
            </Descriptions.Item>
            <Descriptions.Item label='Authorized person'>
              <div>Email: {mapFundResult(result.authorizedPersonEmail)}</div>
              <div>Name: {mapFundResult(result.authorizedPersonName)}</div>
              <div>Phone: {mapFundResult(result.authorizedPersonPhone)}</div>
            </Descriptions.Item>
            <Descriptions.Item label='Social Networks'>
              <div>Website: {mapFundResult(result.website)}</div>
              <div>Facebook: {mapFundResult(result.facebook)}</div>
              <div>Instagram: {mapFundResult(result.instagram)}</div>
              <div>Other Social Links: {mapFundResult(result.otherSocialLinks)}</div>
            </Descriptions.Item>
          </Descriptions>
        </Modal>
      </>
    )
  }

  return (
    <Alert
      closable
      message='Success'
      showIcon
      description='Duplicate data not found'
      type='success'
    />
  )
}

const mapFundResult = (field: T_FundInternalId[]) =>
  field.length
    ? field.map((id) => (
        <li key={id}>
          <Link to={`/ngos/${String(id)}`} target='_blank'>
            <Tag color='processing'>{id}</Tag>
          </Link>
        </li>
      ))
    : '-'

interface CheckCampaignResultProps {
  result: I_CampaignCheckResult
}

export const CheckCampaignResult = ({ result }: CheckCampaignResultProps) => {
  const [isModalVisible, setIsModalVisible] = useState(false)

  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  console.log('result', result)

  if (isDuplicateFound(result)) {
    return (
      <>
        <Alert
          message='Warning'
          showIcon
          description='Duplicate data found'
          type='warning'
          closable
          action={
            <Button size='small' type='primary' onClick={showModal}>
              Detail
            </Button>
          }
        />
        <Modal
          title='Duplicates'
          width={600}
          visible={isModalVisible}
          footer={null}
          onCancel={handleCancel}
        >
          <Descriptions bordered labelStyle={{ width: 175 }} column={1}>
            <Descriptions.Item label='Contacts'>
              <div>Name: {mapCampaignResult(result.name)}</div>
              <div>Phone: {mapCampaignResult(result.phone)}</div>
              <div>Email: {mapCampaignResult(result.email)}</div>
            </Descriptions.Item>
          </Descriptions>
        </Modal>
      </>
    )
  }

  return (
    <Alert
      closable
      message='Success'
      showIcon
      description='Duplicate data not found'
      type='success'
    />
  )
}

const mapCampaignResult = (field: T_CampaignCheckItemResult[]) =>
  field.length
    ? field.map((item) => (
        <li key={String(item.campaignId) + String(item.fundId)}>
          <Link
            to={`/ngos/${String(item.fundId)}/fundraiser/${String(item.campaignId)}`}
            target='_blank'
          >
            <Tag color='processing'>{item.campaignId}</Tag>
          </Link>
        </li>
      ))
    : '-'
