import { Tooltip } from 'antd'
import moment from 'moment'

import { t } from 'languages'
import { I_CampaignRecord } from 'models/campaign'
import { I_Option } from 'models/dictionaries'
import { getStatusName } from 'utils/dictionaries/statuses'
import { formatDate } from 'utils/helpers/date'
import { numberWithSpaces } from 'utils/helpers/text'

type T_Filter = {
  text: string
  value: string
  children?: {
    text: string
    value: string
  }[]
}

const getCampaignStatusFilters = (statuses: I_Option[]): T_Filter[] => {
  return statuses.map((status) =>
    status.id === 'closed_report_ready'
      ? {
          text: status.name,
          value: status.id,
          children: [
            { text: 'Auto', value: 'auto' },
            { text: 'Manual', value: 'manual' },
          ],
        }
      : {
          text: status.name,
          value: status.id,
        },
  )
}

export const getColumns = (statuses: I_Option[]) => [
  {
    title: t('fund.table.id'),
    dataIndex: 'internal_id',
    sorter: (a: I_CampaignRecord, b: I_CampaignRecord) => a.internal_id - b.internal_id,
  },
  {
    title: t('fund.table.name'),
    dataIndex: 'name',
    sorter: (a: I_CampaignRecord, b: I_CampaignRecord) => a.name.length - b.name.length,
  },
  {
    title: t('fund.table.status'),
    dataIndex: 'status_id',
    filters: getCampaignStatusFilters(statuses),
    onFilter: (value: any, record: I_CampaignRecord) => {
      if (value === 'auto' && record.is_delayed) {
        return true
      }
      if (value === 'manual' && !record.is_delayed) {
        return true
      }
      return record.status_id.startsWith(value)
    },
    filterSearch: true,
    render: (statusId: string) => getStatusName([...statuses], statusId),
  },
  {
    title: t('fund.table.endDate'),
    dataIndex: 'report_date',
    sorter: (a: I_CampaignRecord, b: I_CampaignRecord) =>
      moment(a.report_date).unix() - moment(b.report_date).unix(),
    render: (report_date: string) => <>{formatDate(report_date)}</>,
  },
  {
    title: t('fund.table.opened_at'),
    dataIndex: 'opened_at',
    sorter: (a: I_CampaignRecord, b: I_CampaignRecord) =>
      moment(a.opened_at).unix() - moment(b.opened_at).unix(),
    render: (a: string, record: I_CampaignRecord) => <>{formatDate(record.opened_at)}</>,
  },
  {
    title: t('fund.table.time_to_status_change'),
    dataIndex: 'time_to_status_change',
    sorter: (a: I_CampaignRecord, b: I_CampaignRecord) =>
      a.time_to_status_change - b.time_to_status_change,
  },
  {
    title: t('fund.table.target'),
    dataIndex: 'financial_target',
    sorter: (a: I_CampaignRecord, b: I_CampaignRecord) => a.financial_target - b.financial_target,
    render: (value: string) => numberWithSpaces(value),
  },
  {
    title: t('fund.table.left'),
    dataIndex: 'collected_finance',
    sorter: (a: I_CampaignRecord, b: I_CampaignRecord) =>
      a.financial_target - a.collected_finance - (b.financial_target - b.collected_finance),
    render: (_: number, record: I_CampaignRecord) =>
      numberWithSpaces(String(record.financial_target - record.collected_finance)),
  },
  {
    title: t('fund.table.rating'),
    dataIndex: 'rating',
    sorter: (a: I_CampaignRecord, b: I_CampaignRecord) => a.rating.rating - b.rating.rating,
    render: (_: string, record: I_CampaignRecord) => record.rating.rating,
  },
]
