import { createApi } from '@reduxjs/toolkit/query/react'

import { baseQueryWithReAuth } from '../utils'

import { I_SendEveryone } from 'models/notifications'

export const notificationsAPI = createApi({
  reducerPath: 'notificationsAPI',
  baseQuery: baseQueryWithReAuth,
  endpoints: (build) => ({
    sendPushEveryone: build.mutation<void, I_SendEveryone>({
      query: (payload) => ({
        url: `/send-push-everyone`,
        method: 'POST',
        body: payload,
      }),
    }),
  }),
})
