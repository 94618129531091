import {
  PieChartOutlined,
  LogoutOutlined,
  MessageOutlined,
  ScheduleOutlined,
  TableOutlined,
  NotificationOutlined,
  RedoOutlined,
} from '@ant-design/icons'
import { Link } from 'react-router-dom'

import { t } from 'languages'
import { E_Routes } from 'models/routes'

import type { MenuProps } from 'antd'

export type MenuItem = Required<MenuProps>['items'][number]

const getItem = (
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: 'group',
): MenuItem => {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem
}

export const menuItems: MenuProps['items'] = [
  getItem(<Link to={E_Routes.ngos}>{t('sider.ngos')}</Link>, E_Routes.ngos, <ScheduleOutlined />),
  getItem(
    <Link to={E_Routes.complaintsReports}>{t('sider.complaintsAboutReports')}</Link>,
    E_Routes.complaintsReports,
    <MessageOutlined />,
  ),
  getItem(
    <Link to={E_Routes.complaintsFundraisers}>{t('sider.complaintsAboutFundraisers')}</Link>,
    E_Routes.complaintsFundraisers,
    <MessageOutlined />,
  ),
  getItem(
    <Link to={E_Routes.dataReports}>{t('sider.allDataReports')}</Link>,
    E_Routes.dataReports,
    <TableOutlined />,
  ),
  getItem(
    <Link to={E_Routes.dataFundraisers}>{t('sider.allDataFundraisers')}</Link>,
    E_Routes.dataFundraisers,
    <TableOutlined />,
  ),
  getItem(
    <Link to={E_Routes.stats}>{t('sider.stats')}</Link>,
    E_Routes.stats,
    <PieChartOutlined />,
  ),
  getItem(
    <Link to={E_Routes.notifications}>{t('sider.notifications')}</Link>,
    E_Routes.notifications,
    <NotificationOutlined />,
  ),
  getItem(<Link to={E_Routes.refund}>{t('sider.refund')}</Link>, E_Routes.refund, <RedoOutlined />),
  getItem(t('sider.logout'), 'logout', <LogoutOutlined />),
]
