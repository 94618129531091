import { CheckOutlined, CloseCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { Form, Button, Divider, Collapse, Alert, notification, Space, Modal } from 'antd'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import * as Section from './components'

import { CheckCampaignResult } from 'components/CheckResult'
import { Brick } from 'components/Utilities/Brick'
import { t } from 'languages'
import { I_CampaignForm, I_ReasonForBlocking } from 'models/campaign'
import { T_Authorized_person } from 'models/fund'
import { campaignsAPI } from 'services/campaigns'
import { formToFundraiser } from 'utils/forms/campaignForm'

interface FundraiserNewFormProps {
  fundId: string
  curator: T_Authorized_person
}

export const CampaignNewForm = ({ fundId, curator }: FundraiserNewFormProps) => {
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const stateValue = Form.useWatch(['address', 'state'], form)

  const [fetchCreateCampaign, { data, isSuccess, isError, error }] =
    campaignsAPI.useCreateCampaignMutation()

  const handleFinish = (values: I_CampaignForm) => {
    const close_by_time = Boolean(values.closed_at)
    console.log('values', values)
    const data = {
      campaign: formToFundraiser(values, close_by_time, curator),
      fundId,
    }
    fetchCreateCampaign(data)
  }

  const handleCancel = () => {
    navigate(-1)
  }

  // Успешное добавление
  useEffect(() => {
    if (data && isSuccess) {
      notification.open({
        message: t('campaignForm.notifications.createSuccess'),
        icon: <CheckOutlined style={{ color: '#52c41a' }} />,
      })
      navigate(`/ngos/${fundId}/fundraiser/${String(data.campaign.internal_id)}`)
    }
  }, [data, isSuccess])

  // Ошибка при добавлении
  useEffect(() => {
    if (isError) {
      console.log(error)
      notification.open({
        message: t('campaignForm.notifications.createError'),
        description: (error as any)?.data?.message,
        icon: <CloseCircleOutlined style={{ color: '#c4391a' }} />,
      })
    }
  }, [isError, error])

  return (
    <Form
      layout='vertical'
      initialValues={{ curator: { ...curator } }}
      form={form}
      onFinish={handleFinish}
    >
      <div>
        <Divider orientation='left' orientationMargin='0'>
          {t('campaignForm.steps.generalInfo')}
        </Divider>
        <Section.GeneralInfo stateValue={stateValue} />
        <Brick size={2} />
      </div>

      <div>
        <Divider orientation='left' orientationMargin='0'>
          {t('campaignForm.steps.helpType')}
        </Divider>
        <Section.HelpType />
        <Brick size={2} />
      </div>

      <div>
        <Divider orientation='left' orientationMargin='0'>
          {t('campaignForm.steps.media')}
        </Divider>
        <Section.Media />
        <Brick size={2} />
      </div>

      <div>
        <Divider orientation='left' orientationMargin='0'>
          {t('campaignForm.steps.authorizedPerson')}
        </Divider>
        <Section.AuthorizedPerson />
      </div>

      <div>
        <Divider orientation='left' orientationMargin='0'>
          {t('campaignForm.steps.contacts')}
        </Divider>
        <Section.Contacts />
        <Brick size={2} />
      </div>

      <div>
        <Divider orientation='left' orientationMargin='0'>
          {t('campaignForm.steps.marks')}
        </Divider>
        <Section.Marks />
      </div>
      <Brick size={2} />

      <Form.Item>
        <Space>
          <Button size='large' type='primary' htmlType='submit'>
            {t('campaignForm.actions.create')}
          </Button>
          <Button onClick={handleCancel} size='large' htmlType='button'>
            {t('campaignForm.actions.cancel')}
          </Button>
        </Space>
      </Form.Item>
    </Form>
  )
}

interface FundraiserEditFormProps {
  initialValues: I_CampaignForm
  campaignId: string
  reasonsForBlocking: I_ReasonForBlocking
  curator: T_Authorized_person
}

export const CampaignEditForm = ({
  initialValues,
  campaignId,
  reasonsForBlocking,
  curator,
}: FundraiserEditFormProps) => {
  const [form] = Form.useForm<I_CampaignForm>()
  const [formDisabled, setFormDisabled] = useState(true)

  const statusId = Form.useWatch('status_id', form)
  const stateValue = Form.useWatch(['address', 'state'], form)

  const [fetchUpdateCampaign, { data, isSuccess, isError, error }] =
    campaignsAPI.useUpdateCampaignMutation()

  const [
    fetchCheckCampaign,
    { data: checkData, isSuccess: isCheckSuccess, isError: isCheckError, error: checkError },
  ] = campaignsAPI.useCheckCampaignMutation()

  // Выставление причины блока
  useEffect(() => {
    if (statusId === 'suspended') {
      let reasonForBlocking
      if (initialValues.reason_for_blocking) {
        if (initialValues.reason_for_blocking === reasonsForBlocking.blocked) {
          reasonForBlocking = reasonsForBlocking.suspended
        } else {
          reasonForBlocking = initialValues.reason_for_blocking
        }
      } else {
        reasonForBlocking = reasonsForBlocking.suspended
      }
      form.setFieldsValue({
        reason_for_blocking: reasonForBlocking,
      })
      return
    }
    if (statusId === 'blocked') {
      let reasonForBlocking
      if (initialValues.reason_for_blocking) {
        if (initialValues.reason_for_blocking === reasonsForBlocking.suspended) {
          reasonForBlocking = reasonsForBlocking.blocked
        } else {
          reasonForBlocking = initialValues.reason_for_blocking
        }
      } else {
        reasonForBlocking = reasonsForBlocking.blocked
      }
      form.setFieldsValue({
        reason_for_blocking: reasonForBlocking,
      })
    }
  }, [statusId])

  useEffect(() => {
    if (initialValues.status_id !== form.getFieldValue('status_id')) {
      form.setFieldsValue({ status_id: initialValues.status_id })
    }
  }, [initialValues])

  const handleAbleForm = () => {
    setFormDisabled(false)
  }

  const handleFinish = (values: I_CampaignForm) => {
    Modal.confirm({
      title: t('campaignForm.modal.confirm.updateCampaign.title'),
      icon: <ExclamationCircleOutlined />,
      content: t('campaignForm.modal.confirm.updateCampaign.content'),
      okText: t('campaignForm.modal.confirm.updateCampaign.ok'),
      cancelText: t('campaignForm.modal.confirm.updateCampaign.cancel'),
      maskClosable: true,
      onOk: () => {
        const close_by_time =
          moment(values.closed_at).unix() !== moment(initialValues.closed_at).unix()
        const data = formToFundraiser(values, close_by_time, curator)
        fetchUpdateCampaign({ campaign: data, campaignId })
      },
    })
  }

  const handleReset = () => {
    form.resetFields()
    setFormDisabled(true)
  }

  const handleCampaignCheck = () => {
    fetchCheckCampaign(campaignId)
  }

  // Успешное редактирование
  useEffect(() => {
    if (data && isSuccess) {
      notification.open({
        message: t('campaignForm.notifications.updateSuccess'),
        icon: <CheckOutlined style={{ color: '#52c41a' }} />,
      })
      setFormDisabled(true)
      if (data.campaign.status_id !== statusId) {
        notification.info({
          message: t('campaignForm.notifications.updateInfoMessage'),
          description: t('campaignForm.notifications.updateInfoDescription'),
        })
      }
    }
  }, [data, isSuccess])

  // Ошибка при редактировании
  useEffect(() => {
    if (isError) {
      console.log(error)
      notification.open({
        message: t('campaignForm.notifications.updateError'),
        description: (error as any)?.data?.message,
        icon: <CloseCircleOutlined style={{ color: '#c4391a' }} />,
        duration: 10,
      })
    }
  }, [isError, error])

  // Успешное проверка
  useEffect(() => {
    if (isCheckSuccess) {
      notification.open({
        message: t('campaignForm.notifications.checkSuccess'),
        icon: <CheckOutlined style={{ color: '#52c41a' }} />,
      })
    }
  }, [isCheckSuccess])

  // Ошибка при проверке
  useEffect(() => {
    if (isCheckError) {
      notification.open({
        message: t('campaignForm.notifications.checkError'),
        description: (checkError as any)?.data?.message,
        icon: <CloseCircleOutlined style={{ color: '#c4391a' }} />,
        duration: 10,
      })
    }
  }, [isCheckError, checkError])

  return (
    <>
      <Form
        initialValues={initialValues}
        autoComplete='off'
        layout='vertical'
        form={form}
        onFinish={handleFinish}
      >
        <Collapse>
          <Collapse.Panel forceRender header={t('campaignForm.steps.generalInfo')} key='1'>
            <Section.GeneralInfo stateValue={stateValue} withStatus disabled={formDisabled} />
          </Collapse.Panel>

          <Collapse.Panel forceRender header={t('campaignForm.steps.helpType')} key='2'>
            <Section.HelpType disabled={formDisabled} />
          </Collapse.Panel>

          <Collapse.Panel forceRender header={t('campaignForm.steps.media')} key='3'>
            <Section.Media
              disabled={formDisabled}
              fileList={[initialValues.main_photo, initialValues.photos]}
              docs={{
                media: initialValues.documents,
              }}
            />
          </Collapse.Panel>

          <Collapse.Panel forceRender header={t('campaignForm.steps.authorizedPerson')} key='4'>
            <Section.AuthorizedPerson />
          </Collapse.Panel>

          <Collapse.Panel forceRender header={t('campaignForm.steps.contacts')} key='5'>
            <Section.Contacts disabled={formDisabled} />
          </Collapse.Panel>

          <Collapse.Panel forceRender header={t('campaignForm.steps.marks')} key='6'>
            <Section.Marks disabled={formDisabled} />
          </Collapse.Panel>
        </Collapse>
        {checkData && <CheckCampaignResult result={checkData} />}
        {!formDisabled && (
          <Alert showIcon closable message='Info' description={t('alerts.edit')} type='info' />
        )}
        <Brick size={2} />

        {formDisabled ? (
          <Space>
            <Button onClick={handleAbleForm} size='large' type='primary'>
              {t('campaignForm.actions.edit')}
            </Button>
            <Button onClick={handleCampaignCheck} size='large' type='dashed' htmlType='button'>
              {t('campaignForm.actions.check')}
            </Button>
          </Space>
        ) : (
          <Form.Item>
            <Space>
              <Button size='large' type='primary' htmlType='submit'>
                {t('campaignForm.actions.save')}
              </Button>
              <Button onClick={handleReset} size='large'>
                {t('campaignForm.actions.cancel')}
              </Button>
            </Space>
          </Form.Item>
        )}
        <Brick size={2} />
      </Form>
    </>
  )
}
