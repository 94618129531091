import './styles.scss'

export const getSizedClassForSpacer = (
  className: string,
  size: number,
  plusHalf: boolean,
): string => {
  if (size || size === 0 || plusHalf) {
    const classNameWithSize = className + `-${size === 0 ? 0 : size || 1}${plusHalf ? '-5' : ''}`
    return classNameWithSize
  } else {
    return className
  }
}

interface BrickProps {
  size?: number
  plusHalf?: boolean
}

export function Brick({ size = 2, plusHalf = false }: BrickProps) {
  return <div className={getSizedClassForSpacer('brick', size, plusHalf)}></div>
}
