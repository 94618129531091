import { CloseCircleOutlined } from '@ant-design/icons'
import { Button, Input, Typography, Form, Divider, notification } from 'antd'
import { useEffect } from 'react'

import './styles.scss'

import { Brick } from 'components/Utilities/Brick'
import { useStoreDispatch } from 'hooks/useStoreDispatch'
import { t } from 'languages'
import { authAPI } from 'services/auth'
import { signIn } from 'store/profile'

export const AuthLayout = () => {
  const dispatch = useStoreDispatch()
  const [fetchSignIn, { data, isSuccess, isError, error }] = authAPI.useSignInMutation()

  const handleFinish = (values: { login: string; password: string }) => {
    fetchSignIn({ login: values.login, password: values.password })
  }

  // Успешная аутентификация
  useEffect(() => {
    if (data && isSuccess) {
      dispatch(signIn(data))
    }
  }, [data, isSuccess])

  // Ошибка при аутентификации
  useEffect(() => {
    if (isError) {
      notification.open({
        message: t('notifications.authError'),
        description: (error as any)?.data?.message,
        icon: <CloseCircleOutlined style={{ color: '#c4391a' }} />,
      })
      console.log(`Ошибка: ${JSON.stringify({ error })}`)
    }
  }, [isError, error])

  return (
    <div className='auth'>
      <div className='container'>
        <Typography.Title level={3}>{t('auth.title')}</Typography.Title>
        <Divider />
        <Form
          name='signIn'
          initialValues={{ remember: true }}
          onFinish={handleFinish}
          autoComplete='off'
          layout='vertical'
          className='form'
        >
          <Form.Item label={t('auth.form.email.label')} name='login' rules={[{ required: true }]}>
            <Input />
          </Form.Item>

          <Form.Item
            label={t('auth.form.password.label')}
            name='password'
            rules={[{ required: true }]}
          >
            <Input.Password />
          </Form.Item>
          <Brick size={4} />
          <Form.Item>
            <Button type='primary' htmlType='submit' block>
              {t('auth.form.signIn')}
            </Button>
          </Form.Item>
        </Form>
        <Divider />
      </div>
    </div>
  )
}
