import { CheckOutlined, CloseCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { Alert, Button, Collapse, Divider, Form, Modal, notification, Space } from 'antd'
import { FieldError } from 'rc-field-form/es/interface'
import { forwardRef, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import * as Section from './components'

import { CheckFundResult } from 'components/CheckResult'
import { Brick } from 'components/Utilities/Brick'
import { t } from 'languages'
import { I_FundForm } from 'models/fund'
import { fundsAPI } from 'services/funds'
import { formToFund } from 'utils/forms/fundForm'

interface I_FundNewFormProps {
  onErrorDetected: (errors: FieldError[]) => void
}

export const FundNewForm = forwardRef<HTMLDivElement, I_FundNewFormProps>(
  ({ onErrorDetected }, ref) => {
    const navigate = useNavigate()
    const [form] = Form.useForm()
    const [formUpdater, setFormUpdater] = useState(0)

    const handleErrorFieldsDetect = () => {
      setFormUpdater((prev) => ++prev)
    }

    useEffect(() => {
      const fields = form.getFieldsError()
      const errors = fields.filter((field) => field.errors.length)
      onErrorDetected(errors)
    }, [formUpdater])

    const [fetchCreateFund, { data, isSuccess, isError, error }] = fundsAPI.useCreateFundMutation()

    const handleFinish = (values: I_FundForm) => {
      const data = formToFund(values)
      fetchCreateFund(data)
    }

    const handleCancel = () => {
      navigate(-1)
    }

    // Успешное создание
    useEffect(() => {
      if (data && isSuccess) {
        notification.open({
          message: t('fundForm.notifications.createSuccess'),
          icon: <CheckOutlined style={{ color: '#52c41a' }} />,
        })
        navigate('/ngos/' + data.fund.internal_id)
      }
    }, [data, isSuccess])

    // Ошибка при создании
    useEffect(() => {
      if (isError) {
        notification.open({
          message: t('fundForm.notifications.createError'),
          description: (error as any)?.data?.message,
          icon: <CloseCircleOutlined style={{ color: '#c4391a' }} />,
          duration: 10,
        })
      }
    }, [isError, error])

    return (
      <Form
        layout='vertical'
        autoComplete='off'
        form={form}
        onValuesChange={handleErrorFieldsDetect}
        onFinishFailed={handleErrorFieldsDetect}
        onFinish={handleFinish}
      >
        <div ref={ref}>
          <div id='0'>
            <Divider orientation='left' orientationMargin='0'>
              {t('fundForm.steps.generalInfo')}
            </Divider>
            <Section.GeneralInfo />
            <Brick size={2} />
          </div>

          <div id='1'>
            <Divider orientation='left' orientationMargin='0'>
              {t('fundForm.steps.legalInfo')}
            </Divider>
            <Section.LegalInfo />
            <Brick size={2} />
          </div>

          <div id='2'>
            <Divider orientation='left' orientationMargin='0'>
              {t('fundForm.steps.fundManager')}
            </Divider>
            <Section.FundManager />
            <Brick size={2} />
          </div>

          <div id='3'>
            <Divider orientation='left' orientationMargin='0'>
              {t('fundForm.steps.authorizedPerson')}
            </Divider>
            <Section.AuthorizedPerson />
            <Brick size={2} />
          </div>

          <div id='4'>
            <Divider orientation='left' orientationMargin='0'>
              {t('fundForm.steps.bankDetails')}
            </Divider>
            <Section.BankDetails />
            <Brick size={2} />
          </div>

          <div id='5'>
            <Divider orientation='left' orientationMargin='0'>
              {t('fundForm.steps.socialInfo')}
            </Divider>
            <Section.SocialInfo />
            <Brick size={2} />
          </div>

          <div id='6'>
            <Divider orientation='left' orientationMargin='0'>
              {t('fundForm.steps.personalInfo')}
            </Divider>
            <Section.PersonalInfo />
          </div>
          <Brick size={2} />
        </div>
        <Brick size={2} />

        <Form.Item>
          <Space>
            <Button size='large' type='primary' htmlType='submit'>
              {t('fundForm.actions.create')}
            </Button>
            <Button onClick={handleCancel} size='large' htmlType='button'>
              {t('fundForm.actions.cancel')}
            </Button>
          </Space>
        </Form.Item>
      </Form>
    )
  },
)
FundNewForm.displayName = 'FundNewForm'

interface FundEditFormProps {
  initialValues: I_FundForm
  fundId: string
}

export const FundEditForm = ({ initialValues, fundId }: FundEditFormProps) => {
  const [form] = Form.useForm()
  const [formDisabled, setFormDisabled] = useState<boolean>(true)

  const [
    fetchUpdateFund,
    { isSuccess: isUpdateSuccess, isError: isUpdateError, error: updateError },
  ] = fundsAPI.useUpdateFundMutation()
  const [
    fetchFundCheck,
    { data: checkData, isSuccess: isCheckSuccess, isError: isCheckError, error: checkError },
  ] = fundsAPI.useFundCheckMutation()

  const handleAbleForm = () => {
    setFormDisabled(false)
  }

  const handleFinish = (values: I_FundForm) => {
    console.log(values, 'values')
    Modal.confirm({
      title: t('fundForm.modal.confirm.updateFund.title'),
      icon: <ExclamationCircleOutlined />,
      content: t('fundForm.modal.confirm.updateFund.content'),
      okText: t('fundForm.modal.confirm.updateFund.ok'),
      cancelText: t('fundForm.modal.confirm.updateFund.cancel'),
      maskClosable: true,
      onOk: () => {
        const data = formToFund(values)
        fetchUpdateFund({ fund: data, fundId })
      },
    })
  }

  const handleReset = () => {
    form.resetFields()
    setFormDisabled(true)
  }

  const handleFundCheck = () => {
    fetchFundCheck(fundId)
  }

  // Успешное редактирование
  useEffect(() => {
    if (isUpdateSuccess) {
      notification.open({
        message: t('fundForm.notifications.updateSuccess'),
        icon: <CheckOutlined style={{ color: '#52c41a' }} />,
      })
      setFormDisabled(true)
    }
  }, [isUpdateSuccess])

  // Ошибка при редактировании
  useEffect(() => {
    if (isUpdateError) {
      notification.open({
        message: t('fundForm.notifications.updateError'),
        description: (updateError as any)?.data?.message,
        icon: <CloseCircleOutlined style={{ color: '#c4391a' }} />,
        duration: 10,
      })
    }
  }, [isUpdateError, updateError])

  // Успешное проверка
  useEffect(() => {
    if (isCheckSuccess) {
      notification.open({
        message: t('fundForm.notifications.checkSuccess'),
        icon: <CheckOutlined style={{ color: '#52c41a' }} />,
      })
    }
  }, [isCheckSuccess])

  // Ошибка при проверке
  useEffect(() => {
    if (isCheckError) {
      notification.open({
        message: t('fundForm.notifications.checkError'),
        description: (checkError as any)?.data?.message,
        icon: <CloseCircleOutlined style={{ color: '#c4391a' }} />,
        duration: 10,
      })
    }
  }, [isCheckError, checkError])

  return (
    <Form
      autoComplete='off'
      initialValues={initialValues}
      layout='vertical'
      form={form}
      onFinish={handleFinish}
    >
      <Collapse>
        <Collapse.Panel forceRender header={t('fundForm.steps.generalInfo')} key='1'>
          <Section.GeneralInfo withStatus disabled={formDisabled} />
        </Collapse.Panel>

        <Collapse.Panel forceRender header={t('fundForm.steps.legalInfo')} key='2'>
          <Section.LegalInfo
            docs={{
              it_pan_document: initialValues.legal_info.it_pan_document,
              registration_document: initialValues.legal_info.it_pan_document,
              fcra_document: initialValues.legal_info.fcra_document,
              gsn_certificate: initialValues.legal_info.gsn_certificate,
              other_documents: initialValues.legal_info.other_documents,
            }}
            disabled={formDisabled}
          />
        </Collapse.Panel>

        <Collapse.Panel forceRender header={t('fundForm.steps.fundManager')} key='3'>
          <Section.FundManager disabled={formDisabled} />
        </Collapse.Panel>

        <Collapse.Panel forceRender header={t('fundForm.steps.authorizedPerson')} key='4'>
          <Section.AuthorizedPerson disabled={formDisabled} />
        </Collapse.Panel>

        <Collapse.Panel forceRender header={t('fundForm.steps.bankDetails')} key='5'>
          <Section.BankDetails disabled={formDisabled} />
        </Collapse.Panel>

        <Collapse.Panel forceRender header={t('fundForm.steps.socialInfo')} key='6'>
          <Section.SocialInfo
            fileList={form.getFieldValue('brand_photo') || initialValues.brand_photo}
            disabled={formDisabled}
          />
        </Collapse.Panel>

        <Collapse.Panel forceRender header={t('fundForm.steps.personalInfo')} key='7'>
          <Section.PersonalInfo
            fileList={[
              initialValues.request_author.personPhoto,
              initialValues.request_author.passportPhoto,
              initialValues.request_author.personPassportPhoto,
            ]}
            disabled={formDisabled}
          />
        </Collapse.Panel>
      </Collapse>
      {checkData && <CheckFundResult result={checkData.result} />}
      {!formDisabled && (
        <Alert message='Info' description={t('alerts.edit')} showIcon type='info' closable />
      )}
      <Brick size={2} />
      {formDisabled ? (
        <Space>
          <Button onClick={handleAbleForm} size='large' type='primary'>
            {t('fundForm.actions.edit')}
          </Button>
          <Button size='large' type='dashed' htmlType='button' onClick={handleFundCheck}>
            {t('fundForm.actions.check')}
          </Button>
        </Space>
      ) : (
        <Form.Item>
          <Space>
            <Button size='large' type='primary' htmlType='submit'>
              {t('fundForm.actions.save')}
            </Button>
            <Button onClick={handleReset} size='large' type='default'>
              {t('fundForm.actions.cancel')}
            </Button>
          </Space>
        </Form.Item>
      )}
    </Form>
  )
}
