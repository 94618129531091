import { Avatar, Breadcrumb, Button, Table, Typography, Divider, Space, Tag, Row, Col } from 'antd'
import moment from 'moment'
import { Link, useNavigate, useParams } from 'react-router-dom'

import { getColumns } from './data'
import { CommentField } from './extensions/CommentField'

import { FundEditForm } from 'components/Forms'
import { Brick } from 'components/Utilities/Brick'
import { ErrorFeedback } from 'components/Utilities/ErrorFeedback'
import { Loader } from 'components/Utilities/Loader'
import { t } from 'languages'
import { campaignsAPI } from 'services/campaigns'
import { dictionariesAPI } from 'services/dictionaries'
import { fundsAPI } from 'services/funds'
import { getStatusName } from 'utils/dictionaries/statuses'
import { fundToForm } from 'utils/forms/fundForm'
import { E_FormatDate } from 'utils/helpers/date'
import { formatToDataSource } from 'utils/helpers/table'

export const FundPage = () => {
  const navigate = useNavigate()
  const params = useParams()

  // Получение фонда
  const { data: fundData, isLoading: isFundLoading } = fundsAPI.useGetFundQuery(params.fundId!)
  const { data: campaignsData, isLoading: isCampaignsLoading } =
    campaignsAPI.useGetCampaignsByFundQuery(params.fundId!)

  // Получение статусов фонда
  const { data: fundStatuses, isLoading: isFundStatusesLoading } =
    dictionariesAPI.useGetFundStatusesQuery()

  // Получение статусов сбора
  const { data: campaignStatuses, isLoading: isCampaignStatusesLoading } =
    dictionariesAPI.useGetCampaignStatusesQuery()

  if (isFundLoading || isCampaignsLoading || isFundStatusesLoading || isCampaignStatusesLoading)
    return <Loader />

  if (fundData && campaignsData && fundStatuses && campaignStatuses) {
    const dataTable = formatToDataSource(campaignsData.campaigns)
    const columnsTable = getColumns(campaignStatuses.statuses)
    const { fund } = fundData
    const status = getStatusName(fundStatuses.statuses, fund.status_id)

    return (
      <div>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to='/ngos'>{t('breadcrumbs.NGOs')}</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            {t('breadcrumbs.NGO')} — {fund.name}
          </Breadcrumb.Item>
        </Breadcrumb>
        <div className='site-layout-content'>
          <Row>
            <Avatar
              size={128}
              shape='square'
              src={fund.brand_photo.url}
              style={{ marginRight: 12, borderRadius: 4 }}
            />
            <Col span={8} style={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
              <Space>
                <Typography.Text strong>{fund.name}</Typography.Text>
              </Space>
              <Space>
                <Typography.Text>NGO Id: </Typography.Text>
                <Typography.Text strong>{fund.internal_id}</Typography.Text>
              </Space>
              <Space>
                <Typography.Text>{t('fund.info.dateCreation')}</Typography.Text>
                <Typography.Text strong>
                  {moment(fund.created_at).zone('+05:30').format(E_FormatDate.extend)}
                </Typography.Text>
              </Space>
              <Space>
                <Typography.Text>{t('fund.info.foundationDate')}</Typography.Text>
                <Typography.Text strong>
                  {moment(fund.founding_date).format(E_FormatDate.default)}
                </Typography.Text>
              </Space>
              <Space>
                <Typography.Text>{t('fund.info.status')}</Typography.Text>
                <Typography.Text strong>
                  <Tag color='processing'>{status}</Tag>
                </Typography.Text>
              </Space>
            </Col>
          </Row>
          <Brick />

          <Divider orientation='left' orientationMargin='0'>
            <Typography.Title level={3}>{t('fund.title.comment')}</Typography.Title>
          </Divider>
          <CommentField fundId={params.fundId!} initialValue={fund.comment} />
          <Brick />

          <Divider orientation='left' orientationMargin='0'>
            <Typography.Title level={3}>{t('fund.title.edit')}</Typography.Title>
          </Divider>
          <div style={{ width: '90%' }}>
            <FundEditForm initialValues={fundToForm(fund)} fundId={params.fundId!} />
          </div>
          <Brick />

          <Divider orientation='left' orientationMargin='0'>
            <Typography.Title level={3}>{t('fund.title.table')}</Typography.Title>
          </Divider>
          <Link to={`/ngos/${params.fundId}/fundraiser/new`}>
            <Button size='large' type='primary'>
              {t('fund.createFundraiser')}
            </Button>
          </Link>
          <Brick />
          <Table
            columns={columnsTable}
            dataSource={dataTable}
            onRow={(record) => {
              return {
                onClick: () => {
                  navigate(`fundraiser/${String(record.internal_id)}`)
                },
              }
            }}
          />
          <Brick />
        </div>
      </div>
    )
  }

  return <ErrorFeedback />
}
