import { PlusOutlined, UploadOutlined } from '@ant-design/icons'
import { Button } from 'antd'

export const UploadButton = () => (
  <div>
    <PlusOutlined />
    <div style={{ marginTop: 8 }}>Upload</div>
  </div>
)

interface I_UploadFileButtonProps {
  disabled: boolean
}

export const UploadFileButton = ({ disabled }: I_UploadFileButtonProps) => (
  <Button disabled={disabled} icon={<UploadOutlined />}>
    Upload
  </Button>
)
