import { Form, Modal, Upload } from 'antd'
import { useState } from 'react'

import { UploadButton } from 'components/UploadButton'
import { filesAPI } from 'services/files'
import {
  beforeImageUpload,
  customRequestUpload,
  getBase64,
  normalizePrivateImages,
  uploadImageAcceptFiles,
  uploadImageExtraText,
} from 'utils/forms/fileUploader'

enum E_Upload {
  face = 'face',
  document = 'document',
  faceDocument = 'faceDocument',
}

interface PersonalInfoProps {
  fileList?: any[]
  disabled?: boolean
}

export const PersonalInfo = ({ fileList, disabled }: PersonalInfoProps) => {
  const [fetchUpload] = filesAPI.useUploadPrivateFileMutation()

  const [uploadProcess, setUploadProcess] = useState({
    [E_Upload.face]: {
      previewVisible: false,
      previewImage: '',
      previewTitle: '',
      fileList: fileList ? fileList[0] : [],
    },
    [E_Upload.document]: {
      previewVisible: false,
      previewImage: '',
      previewTitle: '',
      fileList: fileList ? fileList[1] : [],
    },
    [E_Upload.faceDocument]: {
      previewVisible: false,
      previewImage: '',
      previewTitle: '',
      fileList: fileList ? fileList[2] : [],
    },
  })

  const handlePreview = async (file: any, key: E_Upload) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }
    setUploadProcess((prev) => ({
      ...prev,
      [key]: {
        ...prev[key],
        previewImage: file.url || file.preview,
        previewVisible: true,
        previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
      },
    }))
  }

  const handleClosePreview = (key: E_Upload) => () => {
    setUploadProcess((prev) => ({
      ...prev,
      [key]: {
        ...prev[key],
        previewVisible: false,
      },
    }))
  }

  const handleChangePhoto = ({ fileList }: any, key: E_Upload) => {
    setUploadProcess((prev) => ({
      ...prev,
      [key]: {
        ...prev[key],
        fileList,
      },
    }))
  }
  return (
    <>
      <Form.Item
        name={['request_author', 'personPhoto']}
        label='Face photo'
        valuePropName='fileList'
        getValueFromEvent={normalizePrivateImages}
        rules={[{ required: true }]}
        extra={uploadImageExtraText}
      >
        <Upload
          accept={uploadImageAcceptFiles}
          listType='picture-card'
          customRequest={(options) => customRequestUpload(options, fetchUpload)}
          onPreview={(file) => handlePreview(file, E_Upload.face)}
          onChange={(e) => handleChangePhoto(e, E_Upload.face)}
          beforeUpload={beforeImageUpload}
          disabled={disabled}
        >
          {uploadProcess[E_Upload.face].fileList.length >= 1 ? null : <UploadButton />}
        </Upload>
      </Form.Item>
      <Modal
        visible={uploadProcess[E_Upload.face].previewVisible}
        title={uploadProcess[E_Upload.face].previewTitle}
        footer={null}
        onCancel={handleClosePreview(E_Upload.face)}
      >
        <img
          alt='preview main photo'
          style={{ width: '100%' }}
          src={uploadProcess[E_Upload.face].previewImage}
        />
      </Modal>
      <Form.Item
        name={['request_author', 'passportPhoto']}
        label='Photo ID, driver license or passport'
        valuePropName='fileList'
        getValueFromEvent={normalizePrivateImages}
        rules={[{ required: true }]}
        extra={uploadImageExtraText}
      >
        <Upload
          accept={uploadImageAcceptFiles}
          listType='picture-card'
          customRequest={(options) => customRequestUpload(options, fetchUpload)}
          onPreview={(file) => handlePreview(file, E_Upload.document)}
          onChange={(e) => handleChangePhoto(e, E_Upload.document)}
          beforeUpload={beforeImageUpload}
          disabled={disabled}
        >
          {uploadProcess[E_Upload.document].fileList.length >= 1 ? null : <UploadButton />}
        </Upload>
      </Form.Item>
      <Modal
        visible={uploadProcess[E_Upload.document].previewVisible}
        title={uploadProcess[E_Upload.document].previewTitle}
        footer={null}
        onCancel={handleClosePreview(E_Upload.document)}
      >
        <img
          alt='preview main photo'
          style={{ width: '100%' }}
          src={uploadProcess[E_Upload.document].previewImage}
        />
      </Modal>

      <Form.Item
        name={['request_author', 'personPassportPhoto']}
        label='Photo of face and document'
        valuePropName='fileList'
        getValueFromEvent={normalizePrivateImages}
        rules={[{ required: true }]}
        extra={uploadImageExtraText}
      >
        <Upload
          accept={uploadImageAcceptFiles}
          listType='picture-card'
          customRequest={(options) => customRequestUpload(options, fetchUpload)}
          onPreview={(file) => handlePreview(file, E_Upload.faceDocument)}
          onChange={(e) => handleChangePhoto(e, E_Upload.faceDocument)}
          beforeUpload={beforeImageUpload}
          disabled={disabled}
        >
          {uploadProcess[E_Upload.faceDocument].fileList.length >= 1 ? null : <UploadButton />}
        </Upload>
      </Form.Item>
      <Modal
        visible={uploadProcess[E_Upload.faceDocument].previewVisible}
        title={uploadProcess[E_Upload.faceDocument].previewTitle}
        footer={null}
        onCancel={handleClosePreview(E_Upload.faceDocument)}
      >
        <img
          alt='preview main photo'
          style={{ width: '100%' }}
          src={uploadProcess[E_Upload.faceDocument].previewImage}
        />
      </Modal>
    </>
  )
}
