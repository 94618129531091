import EN from './en.json'

export enum LocaleEnum {
  en = 'EN',
}

const translations = { [LocaleEnum.en]: EN }

export const t = (key: string): string => {
  const keys = key.split('.')
  return getNestedTranslation(LocaleEnum.en, keys) || key
}

const getNestedTranslation = (language: LocaleEnum, keys: string[]) => {
  return keys.reduce((obj: any, key: string) => {
    return obj?.[key]
  }, translations[language])
}
