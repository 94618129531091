import { CheckOutlined, CloseCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { Alert, Button, Divider, Form, message, Modal, notification, Space } from 'antd'
import { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'

import * as Section from './components'

import { Brick } from 'components/Utilities/Brick'
import { t } from 'languages'
import { I_ReportForm } from 'models/report'
import { campaignsAPI } from 'services/campaigns'
import { reportsAPI } from 'services/reports'

export const ReportCloseForm = () => {
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const params = useParams()

  const [fetchCreateReport, { data, isSuccess, isError, error }] =
    campaignsAPI.useCreateReportMutation()

  const onFinish = (values: I_ReportForm) => {
    if (values.photos.some((photo) => photo?.url)) {
      fetchCreateReport({ campaignId: params.campaignId!, report: values })
      navigate(`/ngos/${params.fundId}/fundraiser/${params.campaignId}`)
    } else {
      message.loading('Some images not uploaded yet')
    }
  }

  // Успешное публикация отчёта
  useEffect(() => {
    if (data && isSuccess) {
      notification.open({
        message: t('reportForm.notifications.createSuccess'),
        icon: <CheckOutlined style={{ color: '#52c41a' }} />,
      })
    }
  }, [data, isSuccess])

  // Ошибка при публикации отчёта
  useEffect(() => {
    if (isError) {
      notification.open({
        message: t('reportForm.notifications.createError'),
        description: (error as any)?.data?.message,
        icon: <CloseCircleOutlined style={{ color: '#c4391a' }} />,
        duration: 10,
      })
    }
  }, [isError, error])

  return (
    <Form autoComplete='off' layout='vertical' form={form} onFinish={onFinish}>
      <div style={{ width: '70%' }}>
        <Section.GeneralInfo />
        <Brick size={4} />
      </div>

      <Form.Item>
        <Button size='large' type='primary' htmlType='submit'>
          {t('reportForm.actions.create')}
        </Button>
        <Link to={`/ngos/${params.fundId}/fundraiser/${params.campaignId}`}>
          <Button size='large'>{t('reportForm.actions.cancel')}</Button>
        </Link>
      </Form.Item>
    </Form>
  )
}

interface FundEditFormProps {
  initialValues: I_ReportForm
  campaignId: string
}

export const ReportEditForm = ({ initialValues, campaignId }: FundEditFormProps) => {
  const [form] = Form.useForm()
  const [activators, setActivators] = useState({
    edit: false,
    formDisabled: true,
  })

  const [fetchUpdateReport, { data, isSuccess, isError, error }] =
    reportsAPI.useUpdateReportMutation()

  const handleAbleForm = () => {
    setActivators((prev) => ({ ...prev, edit: true, formDisabled: false }))
  }

  const handleFinish = (values: I_ReportForm) => {
    if (values.photos.some((photo) => photo?.url)) {
      Modal.confirm({
        title: t('reportForm.modal.confirm.updateReport.title'),
        icon: <ExclamationCircleOutlined />,
        content: t('reportForm.modal.confirm.updateReport.content'),
        okText: t('reportForm.modal.confirm.updateReport.ok'),
        cancelText: t('reportForm.modal.confirm.updateReport.cancel'),
        maskClosable: true,
        onOk: () => {
          fetchUpdateReport({ campaignId: campaignId, report: values })
        },
      })
    } else {
      message.loading('Some images not uploaded yet')
    }
  }

  const onReset = () => {
    form.resetFields()
    setActivators((prev) => ({ ...prev, edit: false, formDisabled: true }))
  }

  // Успешное обновление отчёта
  useEffect(() => {
    if (data && isSuccess) {
      notification.open({
        message: t('reportForm.notifications.updateSuccess'),
        icon: <CheckOutlined style={{ color: '#52c41a' }} />,
      })
      setActivators((prev) => ({ ...prev, edit: false, formDisabled: true }))
    }
  }, [data, isSuccess])

  // Ошибка при обновлении отчёта
  useEffect(() => {
    if (isError) {
      notification.open({
        message: t('reportForm.notifications.updateError'),
        description: (error as any)?.data?.message,
        icon: <CloseCircleOutlined style={{ color: '#c4391a' }} />,
        duration: 10,
      })
    }
  }, [isError, error])

  return (
    <>
      <Form
        initialValues={initialValues}
        autoComplete='off'
        layout='vertical'
        form={form}
        onFinish={handleFinish}
      >
        <div style={{ width: '70%' }}>
          <Divider orientation='left' orientationMargin='0'>
            {t('reportForm.steps.general')}
          </Divider>
          <Section.GeneralInfo
            disabled={activators.formDisabled}
            docs={{
              documents: initialValues.documents,
            }}
          />
          {activators.edit && (
            <Alert message='Info' description={t('alerts.edit')} type='info' closable showIcon />
          )}
        </div>
        <Brick size={2} />

        {activators.formDisabled ? (
          <Button onClick={handleAbleForm} size='large' type='primary'>
            {t('reportForm.actions.edit')}
          </Button>
        ) : (
          <Form.Item>
            <Space>
              <Button size='large' type='primary' htmlType='submit'>
                {t('reportForm.actions.save')}
              </Button>
              <Button onClick={onReset} size='large'>
                {t('reportForm.actions.cancel')}
              </Button>
            </Space>
          </Form.Item>
        )}
      </Form>
    </>
  )
}
