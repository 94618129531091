import { ApexOptions } from 'apexcharts'

import { I_CampaignStatistics, I_FundStatistics, I_ReportStatistics } from 'models/statistics'

const colors = [
  '#00E396',
  '#008FFB',
  '#FEB019',
  '#FF4560',
  '#775DD0',
  '#006262',
  '#CC397B',
  '#20B2AA',
]

/* FUNDS */
export const fundsOptions: ApexOptions = {
  chart: {
    type: 'donut',
  },
  colors: colors,
  responsive: [
    {
      options: {
        legend: {
          position: 'bottom',
        },
      },
    },
  ],
  legend: {
    show: true,
  },
  labels: ['Under consideration', 'Opened', 'Suspended', 'Banned', 'Blocked'],
  plotOptions: {
    pie: {
      donut: {
        labels: {
          show: true,
          name: {
            formatter: () => 'Total',
          },
          total: {
            showAlways: true,
            show: true,
          },
        },
      },
    },
  },
}

export const getFundsSeries = (fundStatistics: I_FundStatistics) => [
  fundStatistics.under_consideration,
  fundStatistics.opened,
  fundStatistics.suspended,
  fundStatistics.banned,
  fundStatistics.blocked,
]

/* REPORTS */
export const reportsOptions: ApexOptions = {
  chart: {
    type: 'donut',
  },
  colors: colors,
  responsive: [
    {
      options: {
        legend: {
          position: 'bottom',
        },
      },
    },
  ],
  labels: ['Manual', 'Auto'],
  plotOptions: {
    pie: {
      donut: {
        labels: {
          show: true,
          name: {
            formatter: () => 'Total',
          },
          total: {
            showAlways: true,
            show: true,
          },
        },
      },
    },
  },
}
export const getReportsSeries = (reportStatistics: I_ReportStatistics) => [
  reportStatistics.written_author,
  reportStatistics.written_automatically,
]

/* CAMPAIGNS */
export const totalFundraiserOptions: ApexOptions = {
  chart: {
    type: 'donut',
  },
  colors: colors,
  responsive: [
    {
      options: {
        legend: {
          position: 'bottom',
        },
      },
    },
  ],
  labels: [
    'Under consideration',
    'Fundraising is underway',
    'Ended. Report getting ready',
    'Ended. Report is ready',
    'Blocked',
    'Denied',
    'Suspended',
  ],
  plotOptions: {
    pie: {
      donut: {
        labels: {
          show: true,
          name: {
            formatter: () => 'Total',
          },
          total: {
            showAlways: true,
            show: true,
          },
        },
      },
    },
  },
}
export const getTotalFundraiserSeries = (fundraiserStatistics: I_CampaignStatistics) => [
  fundraiserStatistics.under_consideration,
  fundraiserStatistics.fundraising_is_underway,
  fundraiserStatistics.closed_preparing_report,
  fundraiserStatistics.closed_report_ready,
  fundraiserStatistics.blocked,
  fundraiserStatistics.denied,
  fundraiserStatistics.suspended,
]

export const activeFundraiserOptions: ApexOptions = {
  chart: {
    type: 'donut',
  },
  colors: colors,
  responsive: [
    {
      options: {
        legend: {
          position: 'bottom',
        },
      },
    },
  ],
  labels: ['Open', 'Extended', 'Extended again'],
  plotOptions: {
    pie: {
      donut: {
        labels: {
          show: true,
          name: {
            formatter: () => 'Total',
          },
          total: {
            showAlways: true,
            show: true,
          },
        },
      },
    },
  },
}
export const getActiveFundraiserSeries = (fundraiserStatistics: I_CampaignStatistics) => [
  fundraiserStatistics.opened,
  fundraiserStatistics.extended,
  fundraiserStatistics.extended_again,
]
