import { Form, Input, Upload, Row, Col, Select, DatePicker } from 'antd'

import { UploadFileButton } from 'components/UploadButton'
import { ErrorFeedback } from 'components/Utilities/ErrorFeedback'
import { Loader } from 'components/Utilities/Loader'
import { dictionariesAPI } from 'services/dictionaries'
import { filesAPI } from 'services/files'
import { getCountries, getStates } from 'utils/dictionaries/locations'
import {
  beforeDocumentUpload,
  customRequestUpload,
  normalizeFile,
  uploadDocumentAcceptFiles,
  uploadDocumentExtraText,
} from 'utils/forms/fileUploader'
import { E_FormatDate, disabledDateBeforeTodayAndToday } from 'utils/helpers/date'

interface LegalInfoProps {
  disabled?: boolean
  docs?: {
    it_pan_document: any[]
    registration_document: any[]
    fcra_document: any[]
    gsn_certificate: any[]
    other_documents: any[]
  } | null
}

export const LegalInfo = ({ disabled = false, docs = null }: LegalInfoProps) => {
  const { data: locations, isLoading: isLocationsLoading } = dictionariesAPI.useGetLocationsQuery()
  const [fetchUpload] = filesAPI.useUploadPrivateFileMutation()

  if (isLocationsLoading) return <Loader relative />

  if (locations) {
    const countries = getCountries(locations)
    const states = getStates(locations)

    return (
      <>
        <Row>
          <Col span={10}>
            <Form.Item
              name={['legal_info', 'description']}
              label='Description'
              rules={[{ required: true }]}
            >
              <Input.TextArea showCount disabled={disabled} />
            </Form.Item>
          </Col>
          <Col span={6} offset={1}>
            <Form.Item
              name={['legal_info', 'it_pan_no']}
              label='Permanent Income Tax Account (IT PAN)'
              rules={[{ required: true }]}
            >
              <Input disabled={disabled} />
            </Form.Item>
          </Col>
          <Col span={6} offset={1}>
            <Form.Item
              name={['legal_info', 'registration_no']}
              label='Registration certificate'
              rules={[{ required: true }]}
            >
              <Input disabled={disabled} />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={10}>
            <Form.Item
              name={['legal_info', 'certificate_validity_period']}
              label='GSN Certificate expiration date'
              rules={[{ required: true }]}
            >
              <DatePicker
                style={{ width: '100%' }}
                disabled={disabled}
                disabledDate={disabledDateBeforeTodayAndToday}
                format={E_FormatDate.shortYear}
                placeholder='Select date'
              />
            </Form.Item>
          </Col>
          <Col span={13} offset={1}>
            <Form.Item name={['legal_info', 'FCRA_validity_period']} label='FCRA expiration date'>
              <DatePicker
                style={{ width: '100%' }}
                disabled={disabled}
                disabledDate={disabledDateBeforeTodayAndToday}
                format={E_FormatDate.shortYear}
                placeholder='Select date'
              />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={10}>
            <Form.Item
              name={['legal_info', 'address', 'country']}
              label='Country'
              rules={[{ required: true }]}
            >
              <Select allowClear disabled={disabled}>
                {countries.map((country) => (
                  <Select.Option key={country.id} value={country.id}>
                    {country.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={13} offset={1}>
            <Form.Item
              name={['legal_info', 'address', 'state']}
              label='State'
              rules={[{ required: true }]}
            >
              <Select allowClear disabled={disabled}>
                {states.map((state) => (
                  <Select.Option key={state.id} value={state.id}>
                    {state.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={10}>
            <Form.Item
              name={['legal_info', 'address', 'city']}
              label='City'
              rules={[{ required: true }]}
            >
              <Input disabled={disabled} />
            </Form.Item>
          </Col>
          <Col span={13} offset={1}>
            <Form.Item
              name={['legal_info', 'address', 'address']}
              label='Legal address'
              rules={[{ required: true }]}
            >
              <Input disabled={disabled} />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          name={['legal_info', 'registration_document']}
          valuePropName='file'
          label='Registration document'
          extra={uploadDocumentExtraText}
          getValueFromEvent={normalizeFile}
          rules={[{ required: true }]}
          style={{ width: '30%' }}
        >
          <Upload
            accept={uploadDocumentAcceptFiles}
            listType='text'
            defaultFileList={docs ? docs.registration_document : []}
            beforeUpload={beforeDocumentUpload}
            customRequest={(options) => customRequestUpload(options, fetchUpload)}
            disabled={disabled}
            maxCount={1}
          >
            <UploadFileButton disabled={disabled} />
          </Upload>
        </Form.Item>

        <Form.Item
          name={['legal_info', 'it_pan_document']}
          valuePropName='file'
          label='IT PAN document'
          extra={uploadDocumentExtraText}
          getValueFromEvent={normalizeFile}
          rules={[{ required: true }]}
          style={{ width: '30%' }}
        >
          <Upload
            accept={uploadDocumentAcceptFiles}
            listType='text'
            beforeUpload={beforeDocumentUpload}
            customRequest={(options) => customRequestUpload(options, fetchUpload)}
            defaultFileList={docs ? docs.it_pan_document : []}
            disabled={disabled}
            maxCount={1}
          >
            <UploadFileButton disabled={disabled} />
          </Upload>
        </Form.Item>

        <Form.Item
          name={['legal_info', 'fcra_document']}
          valuePropName='file'
          label='FCRA document'
          extra={uploadDocumentExtraText}
          getValueFromEvent={normalizeFile}
          style={{ width: '30%' }}
        >
          <Upload
            accept={uploadDocumentAcceptFiles}
            listType='text'
            beforeUpload={beforeDocumentUpload}
            customRequest={(options) => customRequestUpload(options, fetchUpload)}
            defaultFileList={docs ? docs.fcra_document : []}
            disabled={disabled}
            maxCount={1}
          >
            <UploadFileButton disabled={disabled} />
          </Upload>
        </Form.Item>

        <Form.Item
          name={['legal_info', 'gsn_certificate']}
          valuePropName='file'
          label='GSN certificate'
          extra={uploadDocumentExtraText}
          getValueFromEvent={normalizeFile}
          rules={[{ required: true }]}
          style={{ width: '30%' }}
        >
          <Upload
            accept={uploadDocumentAcceptFiles}
            listType='text'
            beforeUpload={beforeDocumentUpload}
            customRequest={(options) => customRequestUpload(options, fetchUpload)}
            defaultFileList={docs ? docs.gsn_certificate : []}
            disabled={disabled}
            maxCount={1}
          >
            <UploadFileButton disabled={disabled} />
          </Upload>
        </Form.Item>

        <Form.Item
          name={['legal_info', 'other_documents']}
          valuePropName='file'
          label='Others'
          extra={uploadDocumentExtraText}
          getValueFromEvent={normalizeFile}
          style={{ width: '30%' }}
        >
          <Upload
            accept={uploadDocumentAcceptFiles}
            listType='text'
            defaultFileList={docs ? docs.other_documents : []}
            beforeUpload={beforeDocumentUpload}
            customRequest={(options) => customRequestUpload(options, fetchUpload)}
            disabled={disabled}
          >
            <UploadFileButton disabled={disabled} />
          </Upload>
        </Form.Item>
      </>
    )
  }

  return <ErrorFeedback />
}
