/* eslint-disable @typescript-eslint/no-explicit-any */
import { message } from 'antd'

import { LocalStorage } from 'utils/helpers/localStorage'

export const uploadImageExtraText = '(.png, .jpeg, .jpg)'
export const uploadImageAcceptFiles = '.png, .jpeg, .jpg'

export const uploadDocumentExtraText = '(.pdf)'
export const uploadDocumentAcceptFiles = '.pdf'

export function getBase64(file: any) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}

export function beforeImageUpload(file: any) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
  if (!isJpgOrPng) {
    message.error('You can only upload a JPG/PNG/JPEG file!')
  }
  const isLt2M = file.size / 1024 / 1024 < 4
  if (!isLt2M) {
    message.error('The picture must be less than 4MB!')
  }
  return isJpgOrPng && isLt2M
}

export const beforeDocumentUpload = (file: any) => {
  const isPdf = file.type === 'application/pdf'
  if (!isPdf) {
    message.error('You can only upload a PDF file!')
  }
  const isLt4M = file.size / 1024 / 1024 < 4
  if (!isLt4M) {
    message.error('The picture must be less than 4MB!')
  }

  const isFilenameLess100S = file.name.length < 100
  if (!isFilenameLess100S) {
    message.error('File name must be less than 100 characters')
  }

  return isPdf && isLt4M && isFilenameLess100S
}

export const normalizeFile = (e: any) => {
  if (Array.isArray(e.fileList) && e.file.response) {
    const files = e.fileList
      .map((file: any) => {
        if (file.id) {
          return file
        }
        if (file.percent === 0) {
          return null
        }
        if (file.response?.[0]) {
          return file.response[0]
        }
        return e.file.response[0]
      })
      .filter((file: any) => file)

    return files
  }
  return e && e.fileList.filter((file: any) => file.percent !== 0)
}

export const normalizeImages = (e: any) => {
  if (Array.isArray(e.fileList) && e.file.response) {
    return e.fileList.map((file: any) => ({ url: file.response?.[0].url || file.url }))
  }
  return e && e.fileList
}

export const normalizePrivateImages = (e: any) => {
  const accessToken = LocalStorage.getUser()
  const param = `?access-token=${accessToken}`
  if (Array.isArray(e.fileList) && e.file.response && accessToken) {
    return e.fileList.map((file: any) => ({
      url: file.response?.[0].url + param || file.url + param,
    }))
  }
  return e && e.fileList
}

export const customRequestUpload = async (options: any, fetchUpload: any) => {
  const data = new FormData()
  data.append('attachments', options.file)
  options.onProgress('...')
  try {
    const response: any = await fetchUpload(data)
    options.onSuccess(response.data.files)
    message.success('File success uploaded!')
  } catch (e) {
    console.log(e)
    options.onError(e)
    message.error('Upload Error: ' + e)
  }
}
