import { DeleteOutlined, CheckOutlined, EyeOutlined } from '@ant-design/icons'
import { Button, Space, Tooltip } from 'antd'
import moment from 'moment'
import { Link } from 'react-router-dom'

import { t } from 'languages'
import { T_CampaignId } from 'models/campaign'
import { I_CampaignReviewRecord, T_CampaignReviewId } from 'models/review'
import { E_FormatDate } from 'utils/helpers/date'

export const getColumns = (
  handleApprove: (reviewId: T_CampaignReviewId, campaignId: T_CampaignId) => void,
  handleRemove: (reviewId: T_CampaignReviewId) => void,
) => [
  {
    title: t('complaintsCampaigns.table.id'),
    dataIndex: 'review_internal_id',
    sorter: (a: I_CampaignReviewRecord, b: I_CampaignReviewRecord) =>
      a.review_internal_id - b.review_internal_id,
  },
  {
    title: t('complaintsCampaigns.table.fundraiserId'),
    dataIndex: 'campaign_internal_id',
    sorter: (a: I_CampaignReviewRecord, b: I_CampaignReviewRecord) =>
      a.campaign_internal_id - b.campaign_internal_id,
  },
  {
    title: t('complaintsCampaigns.table.complaintCount'),
    dataIndex: 'complaintCount',
    sorter: (a: I_CampaignReviewRecord, b: I_CampaignReviewRecord) =>
      a.complaintCount - b.complaintCount,
  },
  {
    title: t('complaintsCampaigns.table.overall'),
    dataIndex: 'overall_rating',
    sorter: (a: I_CampaignReviewRecord, b: I_CampaignReviewRecord) =>
      a.overall_rating - b.overall_rating,
  },
  {
    title: t('complaintsCampaigns.table.relevance'),
    dataIndex: 'relevance',
    sorter: (a: I_CampaignReviewRecord, b: I_CampaignReviewRecord) => a.relevance - b.relevance,
  },
  {
    title: t('complaintsCampaigns.table.feedback'),
    dataIndex: 'feedback',
    sorter: (a: I_CampaignReviewRecord, b: I_CampaignReviewRecord) => a.feedback - b.feedback,
  },
  {
    title: t('complaintsCampaigns.table.completeness'),
    dataIndex: 'completeness_of_information',
    sorter: (a: I_CampaignReviewRecord, b: I_CampaignReviewRecord) =>
      a.completeness_of_information - b.completeness_of_information,
  },
  {
    title: t('complaintsCampaigns.table.trust'),
    dataIndex: 'trust',
    sorter: (a: I_CampaignReviewRecord, b: I_CampaignReviewRecord) => a.trust - b.trust,
  },
  {
    title: t('complaintsCampaigns.table.date'),
    dataIndex: 'created_at',
    sorter: (a: I_CampaignReviewRecord, b: I_CampaignReviewRecord) =>
      moment(a.created_at).unix() - moment(b.created_at).unix(),
    render: (created_at: string) => {
      return <Space size='middle'>{moment(created_at).format(E_FormatDate.default)}</Space>
    },
  },
  {
    key: 'action',
    render: (record: I_CampaignReviewRecord) => (
      <Space size='middle'>
        <Tooltip title={t('tooltip.delete')} placement='topLeft'>
          <Button
            icon={<DeleteOutlined />}
            onClick={() => handleRemove(String(record.review_internal_id))}
          />
        </Tooltip>
        <Tooltip title={t('tooltip.approve')} placement='topLeft'>
          <Button
            icon={<CheckOutlined />}
            onClick={() =>
              handleApprove(String(record.review_internal_id), String(record.campaign_internal_id))
            }
          />
        </Tooltip>
        <Tooltip title={t('tooltip.toFundraiser')} placement='topLeft'>
          <Link
            to={`/ngos/${String(record.fund_internal_id)}/fundraiser/${String(
              record.campaign_internal_id,
            )}`}
            target='_blank'
          >
            <Button icon={<EyeOutlined />} />
          </Link>
        </Tooltip>
      </Space>
    ),
  },
]
