import { createApi } from '@reduxjs/toolkit/query/react'

import { baseQueryWithReAuth } from '../utils'

import {
  I_FundStatuses,
  I_CampaignStatuses,
  I_SocialNames,
  I_Locations,
  I_Subjects,
  I_Targets,
  I_Sizes,
} from 'models/dictionaries'

export const dictionariesAPI = createApi({
  reducerPath: 'dictionariesAPI',
  baseQuery: baseQueryWithReAuth,
  endpoints: (build) => ({
    getFundStatuses: build.query<I_FundStatuses, void>({
      query: () => ({
        url: '/dictionaries/fund_statuses',
      }),
    }),
    getFundSizes: build.query<I_Sizes, void>({
      query: () => ({
        url: '/dictionaries/sizes',
      }),
    }),
    getCampaignStatuses: build.query<I_CampaignStatuses, void>({
      query: () => ({
        url: '/dictionaries/campaign_statuses',
      }),
    }),
    getLocations: build.query<I_Locations, void>({
      query: () => ({
        url: '/dictionaries/locations',
      }),
    }),
    getSocialNames: build.query<I_SocialNames, void>({
      query: () => ({
        url: '/dictionaries/so_nets',
      }),
    }),
    getSubjects: build.query<I_Subjects, void>({
      query: () => ({
        url: '/dictionaries/subjects',
      }),
    }),
    getTargets: build.query<I_Targets, void>({
      query: () => ({
        url: '/dictionaries/targets',
      }),
    }),
  }),
})
