import { Breadcrumb, Button, Table } from 'antd'
import { Link, useNavigate } from 'react-router-dom'

import { getColumns } from './data'

import { Brick } from 'components/Utilities/Brick'
import { ErrorFeedback } from 'components/Utilities/ErrorFeedback'
import { Loader } from 'components/Utilities/Loader'
import { t } from 'languages'
import { dictionariesAPI } from 'services/dictionaries'
import { fundsAPI } from 'services/funds'
import { formatToDataSource } from 'utils/helpers/table'

export const FundsPage = () => {
  const navigate = useNavigate()

  const { data: fundsData, isLoading: isFundLoading } = fundsAPI.useGetFundsQuery()
  const { data: fundStatuses, isLoading: isFundStatusesLoading } =
    dictionariesAPI.useGetFundStatusesQuery()

  if (isFundLoading || isFundStatusesLoading) return <Loader />

  if (fundsData && fundStatuses) {
    const dataTable = formatToDataSource(fundsData.funds)
    const columnsTable = getColumns(fundStatuses.statuses)

    return (
      <>
        <Breadcrumb>
          <Breadcrumb.Item>{t('breadcrumbs.NGOs')}</Breadcrumb.Item>
        </Breadcrumb>
        <div className='site-layout-content'>
          <Link to='new'>
            <Button size='large' type='primary'>
              {t('ngos.createNGO')}
            </Button>
          </Link>
          <Brick size={4} />
          <Table
            columns={columnsTable}
            dataSource={dataTable}
            onRow={(record) => {
              return {
                onClick: () => {
                  navigate(String(record.internal_id))
                },
              }
            }}
          />
        </div>
      </>
    )
  }
  return <ErrorFeedback />
}
