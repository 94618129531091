import { Breadcrumb } from 'antd'

import { ComplaintsTable } from './extensions/ComplaintsTable'

import { t } from 'languages'

export const ComplaintsReportsPage = () => {
  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Item>{t('breadcrumbs.complaintsReports')}</Breadcrumb.Item>
      </Breadcrumb>
      <div className='site-layout-content'>
        <ComplaintsTable />
      </div>
    </div>
  )
}
