import { CheckOutlined, CloseCircleOutlined, StarOutlined } from '@ant-design/icons'
import {
  Breadcrumb,
  Button,
  Col,
  Divider,
  notification,
  Row,
  Space,
  Statistic,
  Typography,
} from 'antd'
import moment from 'moment'
import { useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'

import { CommentField } from './components/CommentField'
import { ReviewsTable } from './components/ReviewsTable'

import { ReportEditForm } from 'components/Forms'
import { Brick } from 'components/Utilities/Brick'
import { ErrorFeedback } from 'components/Utilities/ErrorFeedback'
import { Loader } from 'components/Utilities/Loader'
import { t } from 'languages'
import { campaignsAPI } from 'services/campaigns'
import { dictionariesAPI } from 'services/dictionaries'
import { fundsAPI } from 'services/funds'
import { reportsAPI } from 'services/reports'
import { E_FormatDate } from 'utils/helpers/date'

export const ReportPage = () => {
  const params = useParams()

  const { data: fundData, isLoading: fundIsLoading } = fundsAPI.useGetFundQuery(params.fundId!)
  const { data: campaignData, isLoading: campaignIsLoading } = campaignsAPI.useGetCampaignQuery(
    params.campaignId!,
  )
  const { data: reportData, isLoading: reportIsLoading } = reportsAPI.useGetReportQuery(
    params.campaignId!,
  )

  const { data: campaignStatuses, isLoading: isCampaignStatusesLoading } =
    dictionariesAPI.useGetCampaignStatusesQuery()

  const [
    fetchSendNotification,
    {
      isSuccess: isReportSendNotificationSuccess,
      isError: isReportSendNotificationError,
      error: reportSendNotificationError,
    },
  ] = reportsAPI.useLazySendReportNotificationQuery()

  const sendNotification = () => () => {
    fetchSendNotification(reportData?.report.report_internal_id!)
  }

  // Отправка нотификации прошла успешно
  useEffect(() => {
    if (isReportSendNotificationSuccess) {
      notification.open({
        message: t('notifications.reportNotification.success'),
        icon: <CheckOutlined style={{ color: '#52c41a' }} />,
      })
    }
  }, [isReportSendNotificationSuccess])

  // Отправка нотификации прошла с ошибкой
  useEffect(() => {
    if (isReportSendNotificationError) {
      notification.open({
        message: t('notifications.reportNotification.error'),
        description: (reportSendNotificationError as any).data.message,
        icon: <CloseCircleOutlined style={{ color: '#c4391a' }} />,
      })
    }
  }, [isReportSendNotificationError, reportSendNotificationError])

  if (fundIsLoading || campaignIsLoading || reportIsLoading || isCampaignStatusesLoading)
    return <Loader />

  if (fundData && campaignData && reportData && campaignStatuses) {
    const { fund } = fundData
    const { campaign } = campaignData
    const { report } = reportData

    return (
      <>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to='ngos'>{t('breadcrumbs.NGOs')}</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to={`/ngos/${params.fundId}`}>
              {t('breadcrumbs.NGO')} — {fund.name}
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to={`/ngos/${params.fundId}/fundraiser/${params.campaignId}`}>
              {t('breadcrumbs.fundraiser')} — {campaign.name}
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>{t('breadcrumbs.report')}</Breadcrumb.Item>
        </Breadcrumb>
        <div className='site-layout-content'>
          <Row>
            <Col span={8} style={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
              <Space>
                <Typography.Text strong>{fund.name}</Typography.Text>
              </Space>
              <Space>
                <Typography.Text strong>{campaign.name}</Typography.Text>
              </Space>
              <Space>
                <Typography.Text>Report Id: </Typography.Text>
                <Typography.Text strong>{report.report_internal_id}</Typography.Text>
              </Space>
              <Space>
                <Typography.Text>{t('report.info.dateCreation')}</Typography.Text>
                <Typography.Text strong>
                  {moment(report.created_at).zone('+05:30').format(E_FormatDate.extend)}
                </Typography.Text>
              </Space>
            </Col>
          </Row>
          <Brick size={2} />

          <Button type='primary' onClick={sendNotification()}>
            Send Notification
          </Button>
          <Brick size={2} />

          <Divider orientation='left' orientationMargin='0'>
            <Typography.Title level={3}>{t('report.title.comment')}</Typography.Title>
          </Divider>
          <CommentField initialValue={report.comment} reportId={report.report_internal_id} />
          <Brick size={2} />

          <Divider orientation='left' orientationMargin='0'>
            <Typography.Title level={3}>{t('report.title.rating')}</Typography.Title>
          </Divider>
          <div style={{ display: 'flex', flexDirection: 'row', gap: 64 }}>
            <div>
              <Statistic title='Total' value={report.rating.rating} prefix={<StarOutlined />} />
            </div>
            <div>
              <Statistic title='Clarity of presentation' value={report.rating.clarity} />
            </div>
            <div>
              <Statistic title='Trust in fundraiser' value={report.rating.trust} />
            </div>
            <div>
              <Statistic
                title='Alignment with fundraiser goals'
                value={report.rating.compliance_with_goals}
              />
            </div>
            <div>
              <Statistic
                title='Credibility of proof'
                value={report.rating.persuasiveness_of_evidence}
              />
            </div>
          </div>
          <Brick size={4} />
          <span className='asf' style={{ width: 100 }}></span>
          <Divider orientation='left' orientationMargin='0'>
            <Typography.Title level={3}>{t('report.title.edit')}</Typography.Title>
          </Divider>
          <ReportEditForm initialValues={report} campaignId={params.campaignId!} />
          <Divider orientation='left' orientationMargin='0'>
            <Typography.Title level={3}>{t('report.title.feedback')}</Typography.Title>
          </Divider>
          <ReviewsTable reportId={report.report_internal_id} />
        </div>
      </>
    )
  }

  return <ErrorFeedback />
}
