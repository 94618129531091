import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { SignInResponse, SignInPayload } from './interfaces'

export const authAPI = createApi({
  reducerPath: 'authApi',
  baseQuery: fetchBaseQuery({
    baseUrl: '/api',
    mode: 'cors',
  }),
  endpoints: (build) => ({
    signIn: build.mutation<SignInResponse, SignInPayload>({
      query: (payload) => ({
        url: 'auth',
        method: 'POST',
        body: payload,
      }),
    }),
  }),
})
