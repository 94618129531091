import { Breadcrumb, Row } from 'antd'

import './styles.scss'

import { ReturnTransactionBlock } from './extensions/ReturnTransactionBlock'

import { t } from 'languages'

export const RefundPage = () => {
  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Item>{t('breadcrumbs.refund')}</Breadcrumb.Item>
      </Breadcrumb>
      <Row>
        <ReturnTransactionBlock />
      </Row>
    </div>
  )
}
