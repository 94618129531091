/* eslint-disable @typescript-eslint/no-explicit-any */
import { DeleteOutlined } from '@ant-design/icons'
import { Button, Space, Tag, Tooltip } from 'antd'
import moment from 'moment'

import { t } from 'languages'
import { I_ReportReviewRecord } from 'models/review'
import { E_FormatDate } from 'utils/helpers/date'

export const getColumns = (handleRemove: (id: string) => void) => [
  {
    title: t('report.table.columns.id'),
    dataIndex: 'review_internal_id',
    sorter: (a: I_ReportReviewRecord, b: I_ReportReviewRecord) =>
      a.review_internal_id - b.review_internal_id,
  },
  {
    title: t('report.table.columns.overall'),
    dataIndex: 'overall_rating',
    sorter: (a: I_ReportReviewRecord, b: I_ReportReviewRecord) =>
      a.overall_rating - b.overall_rating,
    render: (_: any, record: I_ReportReviewRecord) => (
      <Tag color='processing'>{record.overall_rating}</Tag>
    ),
  },
  {
    title: t('report.table.columns.persuasiveness'),
    dataIndex: 'persuasiveness_of_evidence',
    sorter: (a: I_ReportReviewRecord, b: I_ReportReviewRecord) =>
      a.persuasiveness_of_evidence - b.persuasiveness_of_evidence,
  },
  {
    title: t('report.table.columns.compliance'),
    dataIndex: 'compliance_with_goals',
    sorter: (a: I_ReportReviewRecord, b: I_ReportReviewRecord) =>
      a.compliance_with_goals - b.compliance_with_goals,
  },
  {
    title: t('report.table.columns.clarity'),
    dataIndex: 'clarity',
    sorter: (a: I_ReportReviewRecord, b: I_ReportReviewRecord) => a.clarity - b.clarity,
  },
  {
    title: t('report.table.columns.trust'),
    dataIndex: 'trust',
    sorter: (a: I_ReportReviewRecord, b: I_ReportReviewRecord) => a.trust - b.trust,
  },
  {
    title: t('report.table.columns.date'),
    dataIndex: 'created_at',
    sorter: (a: I_ReportReviewRecord, b: I_ReportReviewRecord) =>
      moment(a.created_at).unix() - moment(b.created_at).unix(),
    render: (created_at: string) => (
      <Space size='middle'>{moment(created_at).format(E_FormatDate.default)}</Space>
    ),
  },
  {
    key: 'action',
    render: (record: I_ReportReviewRecord) => (
      <Space size='middle'>
        <Tooltip title='Удалить'>
          <Button icon={<DeleteOutlined />} onClick={() => handleRemove(record.id)} />
        </Tooltip>
      </Space>
    ),
  },
]
