import { Breadcrumb, Divider, Typography } from 'antd'
import { Link, useParams } from 'react-router-dom'

import { ReportCloseForm } from 'components/Forms'
import { Brick } from 'components/Utilities/Brick'
import { ErrorFeedback } from 'components/Utilities/ErrorFeedback'
import { Loader } from 'components/Utilities/Loader'
import { t } from 'languages'
import { campaignsAPI } from 'services/campaigns'
import { fundsAPI } from 'services/funds'

export const ReportClosePage = () => {
  const params = useParams()

  const { data: fundData, isLoading: fundIsLoading } = fundsAPI.useGetFundQuery(params.fundId!)
  const { data: campaignData, isLoading: campaignIsLoading } = campaignsAPI.useGetCampaignQuery(
    params.campaignId!,
  )

  if (fundIsLoading || campaignIsLoading) return <Loader />

  if (fundData && campaignData) {
    const { fund } = fundData
    const { campaign } = campaignData

    return (
      <>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to='/ngos'>{t('breadcrumbs.NGOs')}</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to={`/ngos/${params.fundId}`}>
              {t('breadcrumbs.NGO')} — {fund.name}
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to={`/ngos/${params.fundId}/fundraiser/${params.campaignId}`}>
              {t('breadcrumbs.fundraiser')} — {campaign.name}
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>{t('breadcrumbs.reportClose')}</Breadcrumb.Item>
        </Breadcrumb>
        <div className='site-layout-content'>
          <Divider orientation='left' orientationMargin='0'>
            <Typography.Title level={3}>{t('reportClose.title.report')}</Typography.Title>
          </Divider>
          <ReportCloseForm />
          <Brick size={8} />
        </div>
      </>
    )
  }

  return <ErrorFeedback />
}
