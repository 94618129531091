import { Breadcrumb, Row } from 'antd'

import './styles.scss'

import { SendEveryoneBlock } from './extensions/SendEveryone'

import { t } from 'languages'

export const NotificationsPage = () => {
  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Item>{t('breadcrumbs.notifications')}</Breadcrumb.Item>
      </Breadcrumb>
      <Row>
        <SendEveryoneBlock />
      </Row>
    </div>
  )
}
