import { createApi } from '@reduxjs/toolkit/query/react'

import { baseQueryWithReAuth } from '../utils'

import { I_Campaign, I_CampaignResponse, I_ReasonForBlocking, T_CampaignId } from 'models/campaign'
import { I_CampaignCheckResult } from 'models/check'
import { I_CommentResponse } from 'models/fund'
import { I_Report, I_ReportResponse } from 'models/report'
import { I_CampaignReviewResponse, T_CampaignReviewId } from 'models/review'

export const campaignsAPI = createApi({
  reducerPath: 'campaignsAPI',
  baseQuery: baseQueryWithReAuth,
  tagTypes: ['campaign', 'campaignReviews', 'campaignsReviews', 'campaignReport'],
  endpoints: (build) => ({
    getCampaigns: build.query<{ campaigns: I_CampaignResponse[] }, void>({
      query: () => ({
        url: `/campaigns`,
      }),
      providesTags: ['campaign', 'campaignReport'],
    }),

    getCampaignsByFund: build.query<{ campaigns: I_CampaignResponse[] }, string>({
      query: (fundId) => ({
        url: `/funds/${fundId}/campaigns`,
      }),
    }),

    getCampaign: build.query<
      { campaign: I_CampaignResponse; reason_for_blocking_text: I_ReasonForBlocking },
      string
    >({
      query: (campaignId) => ({
        url: `/campaigns/${campaignId}`,
      }),
      providesTags: ['campaign', 'campaignReport'],
    }),

    createCampaign: build.mutation<
      { campaign: I_CampaignResponse },
      { campaign: I_Campaign; fundId: string }
    >({
      query: (payload) => ({
        url: `/funds/${payload.fundId}/campaigns`,
        method: 'POST',
        body: payload.campaign,
      }),
    }),

    updateCampaign: build.mutation<
      { campaign: I_CampaignResponse },
      { campaign: I_Campaign; campaignId: string }
    >({
      query: (payload) => ({
        url: `/campaigns/${payload.campaignId}`,
        method: 'PUT',
        body: payload.campaign,
      }),
      invalidatesTags: ['campaign'],
    }),

    updateCampaignComment: build.mutation<
      I_CommentResponse,
      { comment: string; campaignId: string }
    >({
      query: (payload) => ({
        url: `/campaigns/${payload.campaignId}/comment`,
        method: 'PUT',
        body: { comment: payload.comment },
      }),
      invalidatesTags: ['campaign'],
    }),

    closeCampaign: build.mutation<{ campaign: I_CampaignResponse }, { campaignId: string }>({
      query: (payload) => ({
        url: `/campaigns/${payload.campaignId}/close`,
        method: 'POST',
      }),
      invalidatesTags: ['campaign'],
    }),

    createReport: build.mutation<I_ReportResponse, { report: I_Report; campaignId: string }>({
      query: (payload) => ({
        url: `/campaigns/${payload.campaignId}/report`,
        method: 'POST',
        body: payload.report,
      }),
      invalidatesTags: ['campaignReport'],
    }),

    getCampaignReviews: build.query<
      { review: I_CampaignReviewResponse[]; campaignId: T_CampaignId },
      T_CampaignId
    >({
      query: (campaignId) => ({
        url: `/campaigns/${campaignId}/reviews`,
      }),
      providesTags: ['campaignReviews'],
    }),

    getCampaignsReviews: build.query<{ reviews: I_CampaignReviewResponse[] }, void>({
      query: () => ({
        url: `/campaigns/reviews`,
      }),
      providesTags: ['campaignsReviews'],
    }),

    deleteCampaignReview: build.mutation<void, T_CampaignReviewId>({
      query: (reviewId) => ({
        url: `/campaigns/reviews/${reviewId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['campaignReviews', 'campaign', 'campaignsReviews'],
    }),

    approveCampaignReviewComplaint: build.mutation<
      void,
      { reviewId: T_CampaignReviewId; campaignId: T_CampaignId }
    >({
      query: (payload) => ({
        url: `/campaigns/${payload.campaignId}/${payload.reviewId}/complaint`,
        method: 'PUT',
      }),
      invalidatesTags: ['campaignsReviews'],
    }),

    checkCampaign: build.mutation<I_CampaignCheckResult, T_CampaignId>({
      query: (campaignId) => ({
        url: `/campaigns/${campaignId}/check`,
        method: 'GET',
      }),
    }),
  }),
})
