import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Modal, Layout, Menu } from 'antd'
import cn from 'classnames'
import { FC } from 'react'
import { useLocation } from 'react-router-dom'

import './styles.scss'
import { menuItems } from './data'

import { useStoreDispatch } from 'hooks/useStoreDispatch'
import { useStoreSelector } from 'hooks/useStoreSelector'
import { t } from 'languages'
import { logout } from 'store/profile'
import { toggleSidebar } from 'store/ui'

import type { MenuInfo } from 'rc-menu/lib/interface'

export const DashboardLayout: FC = ({ children }) => {
  const dispatch = useStoreDispatch()
  const location = useLocation()

  const collapsed = useStoreSelector((state) => state.ui.sidebarCollapsed)

  const handleLogout = () => {
    Modal.confirm({
      title: t('modal.confirm.logout.title'),
      icon: <ExclamationCircleOutlined />,
      content: t('modal.confirm.logout.content'),
      okText: t('modal.confirm.logout.ok'),
      cancelText: t('modal.confirm.logout.cancel'),
      maskClosable: true,
      onOk: () => {
        dispatch(logout())
      },
    })
  }

  const handleMenuItem = ({ key }: MenuInfo) => {
    switch (key) {
      case 'logout':
        handleLogout()
    }
  }

  return (
    <Layout style={{ minHeight: '100vh', flexDirection: 'row' }}>
      <Layout.Sider collapsible collapsed={collapsed} onCollapse={() => dispatch(toggleSidebar())}>
        <div className={cn('logo', { logo_collapsed: collapsed })}>{t('main.logo')}</div>
        <Menu
          items={menuItems}
          onClick={handleMenuItem}
          theme='dark'
          defaultSelectedKeys={[location.pathname]}
          selectedKeys={[location.pathname]}
          mode='inline'
          className='menu'
        />
      </Layout.Sider>
      <Layout>
        <Layout.Content style={{ margin: '0 16px' }}>{children}</Layout.Content>
        <Layout.Footer style={{ textAlign: 'center' }}>
          {t('main.copyright')} — Cherrity <b>v{process.env.REACT_APP_VERSION}</b>
        </Layout.Footer>
      </Layout>
    </Layout>
  )
}
