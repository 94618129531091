import { CheckOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { notification, Input, Tooltip, Button } from 'antd'
import { ChangeEvent, useEffect, useState } from 'react'

import { t } from 'languages'
import { campaignsAPI } from 'services/campaigns'

interface CommentFieldProps {
  initialValue: string
  campaignId: string
}

export const CommentField = ({ initialValue, campaignId }: CommentFieldProps) => {
  const [comment, setComment] = useState(initialValue)

  const [fetchUpdateCampaignComment, { data, isSuccess, isError, error, isLoading }] =
    campaignsAPI.useUpdateCampaignCommentMutation()

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setComment(e.target.value)
  }

  const handleUpdate = () => {
    fetchUpdateCampaignComment({ comment, campaignId })
  }

  // Изменение комментария прошло успешно
  useEffect(() => {
    if (isSuccess && data) {
      notification.open({
        message: t('notifications.comment.success'),
        icon: <CheckOutlined style={{ color: '#52c41a' }} />,
      })
      setComment(data.comment)
    }
  }, [isSuccess, data])

  // Изменение комментария прошло с ошибкой
  useEffect(() => {
    if (isError) {
      notification.open({
        message: t('notifications.comment.error'),
        description: (error as any)?.data?.message,
        icon: <CloseCircleOutlined style={{ color: '#c4391a' }} />,
      })
    }
  }, [isError, error])

  return (
    <div style={{ display: 'flex', alignItems: 'end', gap: 12 }}>
      <Input.TextArea
        style={{ width: 500 }}
        rows={4}
        showCount
        onChange={handleChange}
        value={comment}
        placeholder='Add comment'
      />
      <Tooltip title='Update'>
        <Button
          disabled={isLoading}
          type='primary'
          onClick={handleUpdate}
          shape='circle'
          icon={<CheckOutlined />}
          placeholder='Add comment'
        />
      </Tooltip>
    </div>
  )
}
