import { TeamOutlined, StarOutlined } from '@ant-design/icons'
import { Breadcrumb, Col, Divider, Row, Avatar, Space, Statistic, Typography, Tag } from 'antd'
import moment from 'moment'
import { useLayoutEffect } from 'react'
import { Link, useParams } from 'react-router-dom'

import { CommentField } from './components/CommentField'
import { ReportButton } from './components/ReportButton'
import { ReviewsTable } from './components/ReviewsTable'

import { CampaignEditForm } from 'components/Forms'
import { Brick } from 'components/Utilities/Brick'
import { ErrorFeedback } from 'components/Utilities/ErrorFeedback'
import { Loader } from 'components/Utilities/Loader'
import { t } from 'languages'
import { campaignsAPI } from 'services/campaigns'
import { dictionariesAPI } from 'services/dictionaries'
import { fundsAPI } from 'services/funds'
import { getStatusName } from 'utils/dictionaries/statuses'
import { fundraiserToForm } from 'utils/forms/campaignForm'
import { E_FormatDate, formatDate } from 'utils/helpers/date'

export const FundraiserPage = () => {
  const params = useParams()

  const { data: campaignStatuses, isLoading: isCampaignStatusesLoading } =
    dictionariesAPI.useGetCampaignStatusesQuery()
  const { data: fundData, isLoading: fundIsLoading } = fundsAPI.useGetFundQuery(params.fundId!)
  const [fetchCampaign, { data: campaignData, isLoading: isCampaignLoading }] =
    campaignsAPI.useLazyGetCampaignQuery()

  useLayoutEffect(() => {
    fetchCampaign(params.campaignId!)
  }, [])

  if (fundIsLoading || isCampaignLoading || isCampaignStatusesLoading) return <Loader />

  if (fundData && campaignStatuses && campaignData) {
    const { fund } = fundData
    const { campaign, reason_for_blocking_text } = campaignData
    const status = getStatusName(campaignStatuses.statuses, campaign.status_id)

    return (
      <>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to='/ngos'>{t('breadcrumbs.NGOs')}</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to={`/ngos/${params.fundId}`}>
              {t('breadcrumbs.NGO')} — {fund.name}
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            {t('breadcrumbs.fundraiser')} — {campaign.name}
          </Breadcrumb.Item>
        </Breadcrumb>
        <div className='site-layout-content'>
          <Row>
            <Avatar
              size={128}
              shape='square'
              src={campaign.main_photo.url}
              style={{ marginRight: 12, borderRadius: 4 }}
            />
            <Col span={12} style={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
              <Space>
                <Typography.Text strong>{fund.name}</Typography.Text>
              </Space>
              <Space>
                <Typography.Text strong>{campaign.name}</Typography.Text>
              </Space>
              <Space>
                <Typography.Text>Fundraiser Id: </Typography.Text>
                <Typography.Text strong>{campaign.internal_id}</Typography.Text>
              </Space>
              <Space>
                <Typography.Text>{t('fundraiser.info.dateCreation')}</Typography.Text>
                <Typography.Text strong>
                  {moment(campaign.created_at).zone('+05:30').format(E_FormatDate.extend)}
                </Typography.Text>
              </Space>
              <Space>
                <Typography.Text>{t('fundraiser.info.openedAt')}</Typography.Text>
                <Typography.Text strong>
                  {formatDate(campaign.opened_at, E_FormatDate.extend)}
                </Typography.Text>
              </Space>
              <Space>
                <Typography.Text>{t('fundraiser.info.extended')}</Typography.Text>
                <Typography.Text strong>
                  {formatDate(campaign.extendedDate, E_FormatDate.extend)}
                </Typography.Text>
              </Space>
              <Space>
                <Typography.Text>{t('fundraiser.info.extendedAgain')}</Typography.Text>
                <Typography.Text strong>
                  {formatDate(campaign.extendedAgainDate, E_FormatDate.extend)}
                </Typography.Text>
              </Space>
              <Space>
                <Typography.Text>{t('fundraiser.info.ended')}</Typography.Text>
                <Typography.Text strong>
                  {formatDate(campaign.report_date, E_FormatDate.extend)}
                </Typography.Text>
              </Space>
              <Space>
                <Typography.Text>{t('fundraiser.info.reportIsReady')}</Typography.Text>
                <Typography.Text strong>
                  {formatDate(campaign.preparingReportDate, E_FormatDate.extend)}
                </Typography.Text>
              </Space>
              <Space>
                <Typography.Text>{t('fundraiser.info.status')}</Typography.Text>
                <Typography.Text strong>
                  <Tag color='processing'>{isCampaignLoading ? '...' : status}</Tag>
                </Typography.Text>
              </Space>
            </Col>
          </Row>
          <Space style={{ width: '100%', marginTop: 16 }}>
            <ReportButton status={campaign.status_id} campaignId={params.campaignId!} />
          </Space>
          <Brick />

          <Divider orientation='left' orientationMargin='0'>
            <Typography.Title level={3}>{t('fundraiser.title.comment')}</Typography.Title>
          </Divider>
          <CommentField initialValue={campaign.comment} campaignId={params.campaignId!} />
          <Brick />

          <Divider orientation='left' orientationMargin='0'>
            <Typography.Title level={3}>{t('fundraiser.title.info')}</Typography.Title>
          </Divider>
          <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 64 }}>
            <div>
              <Statistic
                title={`${t('fundraiser.info.target')} ${campaign.financial_target}`}
                value={campaign.collected_finance}
                prefix={t('fundraiser.info.collected')}
              />
            </div>
            <div>
              <Statistic
                title={
                  t('fundraiser.info.openedAt') +
                  ' ' +
                  moment(campaign.created_at).format(E_FormatDate.default)
                }
                value={campaign.time_to_status_change}
                prefix={t('fundraiser.info.left')}
              />
            </div>
            <div>
              <Statistic
                title={t('fundraiser.info.helped')}
                value={campaign.helped_people}
                prefix={<TeamOutlined />}
              />
            </div>
            <div>
              <Statistic
                title={t('fundraiser.info.rating')}
                value={campaign.rating.rating}
                prefix={<StarOutlined />}
              />
            </div>
          </div>
          <Brick size={4} />

          <Divider orientation='left' orientationMargin='0'>
            <Typography.Title level={3}>{t('fundraiser.title.edit')}</Typography.Title>
          </Divider>
          <CampaignEditForm
            initialValues={fundraiserToForm(campaign)}
            reasonsForBlocking={reason_for_blocking_text}
            campaignId={params.campaignId!}
            curator={fund.authorized_person}
          />
          <Brick />

          <Divider orientation='left' orientationMargin='0'>
            <Typography.Title level={3}>{t('fundraiser.title.reviews')}</Typography.Title>
          </Divider>
          <ReviewsTable campaignId={params.campaignId!} />
        </div>
      </>
    )
  }
  return <ErrorFeedback />
}
