/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form, Input, Modal, Upload } from 'antd'
import { useState } from 'react'

import { UploadButton, UploadFileButton } from 'components/UploadButton'
import { filesAPI } from 'services/files'
import {
  beforeImageUpload,
  beforeDocumentUpload,
  getBase64,
  normalizeFile,
  customRequestUpload,
  normalizeImages,
  uploadImageAcceptFiles,
  uploadImageExtraText,
  uploadDocumentExtraText,
  uploadDocumentAcceptFiles,
} from 'utils/forms/fileUploader'

enum E_Upload {
  main = 'main',
  other = 'other',
}

interface MediaProps {
  fileList?: any[]
  disabled?: boolean
  docs?: {
    media: any[]
  } | null
}

export const Media = ({ disabled = false, fileList, docs = null }: MediaProps) => {
  const [fetchUpload] = filesAPI.useUploadPublicFileMutation()

  const [uploadProcess, setUploadProcess] = useState({
    [E_Upload.main]: {
      previewVisible: false,
      previewImage: '',
      previewTitle: '',
      fileList: fileList ? fileList[0] : [],
    },
    [E_Upload.other]: {
      previewVisible: false,
      previewImage: '',
      previewTitle: '',
      fileList: fileList ? fileList[1] : [],
    },
  })

  const handlePreview = async (file: any, key: E_Upload) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }
    setUploadProcess((prev) => ({
      ...prev,
      [key]: {
        ...prev[key],
        previewImage: file.url || file.preview,
        previewVisible: true,
        previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
      },
    }))
  }

  const handleClosePreview = (key: E_Upload) => () => {
    setUploadProcess((prev) => ({
      ...prev,
      [key]: {
        ...prev[key],
        previewVisible: false,
      },
    }))
  }

  const handleChangePhoto = ({ fileList }: any, key: E_Upload) => {
    setUploadProcess((prev) => ({
      ...prev,
      [key]: {
        ...prev[key],
        fileList,
      },
    }))
  }

  return (
    <>
      <Form.Item
        name='main_photo'
        label='Main photo'
        valuePropName='fileList'
        getValueFromEvent={normalizeImages}
        rules={[{ required: true }]}
        extra={uploadImageExtraText}
      >
        <Upload
          accept={uploadImageAcceptFiles}
          listType='picture-card'
          customRequest={(options) => customRequestUpload(options, fetchUpload)}
          onPreview={(file) => handlePreview(file, E_Upload.main)}
          onChange={(e) => handleChangePhoto(e, E_Upload.main)}
          beforeUpload={beforeImageUpload}
          disabled={disabled}
        >
          {uploadProcess[E_Upload.main].fileList.length >= 1 ? null : <UploadButton />}
        </Upload>
      </Form.Item>
      <Modal
        visible={uploadProcess[E_Upload.main].previewVisible}
        title={uploadProcess[E_Upload.main].previewTitle}
        footer={null}
        onCancel={handleClosePreview(E_Upload.main)}
      >
        <img
          alt='preview main photo'
          style={{ width: '100%' }}
          src={uploadProcess[E_Upload.main].previewImage}
        />
      </Modal>
      <Form.Item
        name='photos'
        valuePropName='fileList'
        label='Other photos'
        getValueFromEvent={normalizeImages}
        rules={[{ required: true }]}
        extra={uploadImageExtraText}
      >
        <Upload
          accept={uploadImageAcceptFiles}
          listType='picture-card'
          customRequest={(options) => customRequestUpload(options, fetchUpload)}
          onPreview={(file) => handlePreview(file, E_Upload.other)}
          onChange={(e) => handleChangePhoto(e, E_Upload.other)}
          beforeUpload={beforeImageUpload}
          disabled={disabled}
        >
          {uploadProcess[E_Upload.other].fileList.length >= 8 ? null : <UploadButton />}
        </Upload>
      </Form.Item>
      <Modal
        visible={uploadProcess[E_Upload.other].previewVisible}
        title={uploadProcess[E_Upload.other].previewTitle}
        footer={null}
        onCancel={handleClosePreview(E_Upload.other)}
      >
        <img
          alt='preview other photo'
          style={{ width: '100%' }}
          src={uploadProcess[E_Upload.other].previewImage}
        />
      </Modal>
      <Form.Item name={['video', 'url']} label='Video'>
        <Input disabled={disabled} />
      </Form.Item>
      <Form.Item
        name='documents'
        valuePropName='file'
        label='Media Documents'
        extra={uploadDocumentExtraText}
        getValueFromEvent={normalizeFile}
        style={{ width: '30%' }}
      >
        <Upload
          accept={uploadDocumentAcceptFiles}
          listType='text'
          defaultFileList={docs ? docs.media : []}
          customRequest={(options) => customRequestUpload(options, fetchUpload)}
          beforeUpload={beforeDocumentUpload}
          disabled={disabled}
        >
          <UploadFileButton disabled={disabled} />
        </Upload>
      </Form.Item>
    </>
  )
}
