import { Col, Form, Input, Row } from 'antd'

import { Brick } from 'components/Utilities/Brick'

interface BankDetailsProps {
  disabled?: boolean
}
export const BankDetails = ({ disabled = false }: BankDetailsProps) => (
  <Row>
    <Col span={10}>
      <Form.Item name='bank_info' label='Bank info'>
        <Input.TextArea showCount disabled={disabled} />
      </Form.Item>
    </Col>
    <Brick size={5} />
  </Row>
)
