import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons'
import { Button, Form, Input, Modal, Select, Space, Upload } from 'antd'
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface'
import { useEffect, useState } from 'react'

import { UploadButton } from 'components/UploadButton'
import { ErrorFeedback } from 'components/Utilities/ErrorFeedback'
import { Loader } from 'components/Utilities/Loader'
import { dictionariesAPI } from 'services/dictionaries'
import { filesAPI } from 'services/files'
import {
  beforeImageUpload,
  customRequestUpload,
  getBase64,
  normalizeImages,
  uploadImageAcceptFiles,
  uploadImageExtraText,
} from 'utils/forms/fileUploader'

interface SocialInfoProps {
  fileList?: any
  disabled?: boolean
}

export const SocialInfo = ({ fileList, disabled }: SocialInfoProps) => {
  const { data: socialNames, isLoading: isSocialNamesLoading } =
    dictionariesAPI.useGetSocialNamesQuery()
  const [fetchUpload] = filesAPI.useUploadPublicFileMutation()

  const [uploadProcess, setUploadProcess] = useState({
    previewVisible: false,
    previewImage: '',
    previewTitle: '',
    fileList: fileList || [],
  })

  const handlePreview = async (file: any) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }
    setUploadProcess((prev) => ({
      ...prev,
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
    }))
  }

  const handleClosePreview = () => setUploadProcess((prev) => ({ ...prev, previewVisible: false }))

  const handleChangePhoto = (info: UploadChangeParam<UploadFile<any>>) => {
    setUploadProcess((prev) => ({ ...prev, fileList: info.fileList }))
  }

  useEffect(() => {
    if (uploadProcess.fileList.length || fileList) {
      setUploadProcess((prev) => ({ ...prev, fileList }))
    }
  }, [fileList])

  if (isSocialNamesLoading) return <Loader relative />

  if (socialNames) {
    return (
      <>
        <Form.List name='social_links'>
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <Space key={key} style={{ alignItems: 'flex-start', width: '100%' }}>
                  <Form.Item
                    {...restField}
                    name={[name, 'type']}
                    rules={[{ required: true, message: 'Please select a Social' }]}
                  >
                    <Select style={{ width: 130 }} placeholder='Social' disabled={disabled}>
                      {socialNames.soNets.map((item) => (
                        <Select.Option key={item.id} value={item.id}>
                          {item.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, 'value']}
                    rules={[{ required: true, message: 'Please enter Link' }]}
                  >
                    <Input placeholder='Link' disabled={disabled} />
                  </Form.Item>
                  <MinusCircleOutlined
                    disabled={disabled}
                    onClick={() => (disabled ? null : remove(name))}
                  />
                </Space>
              ))}
              <Form.Item>
                <Button
                  type='dashed'
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                  disabled={disabled}
                >
                  Add social network
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>

        <Form.Item
          name='brand_photo'
          label='Brand photo'
          valuePropName='fileList'
          getValueFromEvent={normalizeImages}
          rules={[{ required: true }]}
          extra={uploadImageExtraText}
        >
          <Upload
            accept={uploadImageAcceptFiles}
            listType='picture-card'
            onPreview={handlePreview}
            customRequest={(options) => customRequestUpload(options, fetchUpload)}
            onChange={handleChangePhoto}
            beforeUpload={beforeImageUpload}
            disabled={disabled}
          >
            {uploadProcess.fileList.length >= 1 ? null : <UploadButton />}
          </Upload>
        </Form.Item>

        <Modal
          visible={uploadProcess.previewVisible}
          title={uploadProcess.previewTitle}
          footer={null}
          onCancel={handleClosePreview}
        >
          <img
            alt='preview main photo'
            style={{ width: '100%' }}
            src={uploadProcess.previewImage}
          />
        </Modal>

        <Form.Item name='description' label='About fund' rules={[{ required: true }]}>
          <Input.TextArea showCount disabled={disabled} />
        </Form.Item>
      </>
    )
  }

  return <ErrorFeedback />
}
