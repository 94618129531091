import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { LocalStorage } from 'utils/helpers/localStorage'

interface Profile {
  isAuth: boolean
}

const initialState: Profile = {
  isAuth: false,
}

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    signIn: (state, action: PayloadAction<{ access_token: string }>) => {
      LocalStorage.setUser(action.payload.access_token)
      state.isAuth = true
    },
    logout: (state) => {
      LocalStorage.removeUser()
      state.isAuth = false
    },
  },
})

export const { signIn, logout } = profileSlice.actions

export default profileSlice
