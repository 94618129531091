import { CheckOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { notification, Input, Tooltip, Button } from 'antd'
import { ChangeEvent, useEffect, useState } from 'react'

import { t } from 'languages'
import { T_ReportInternalId } from 'models/report'
import { reportsAPI } from 'services/reports'

interface CommentFieldProps {
  initialValue: string
  reportId: T_ReportInternalId
}

export const CommentField = ({ initialValue, reportId }: CommentFieldProps) => {
  const [comment, setComment] = useState(initialValue)

  const [fetchUpdateReportComment, { data, isSuccess, isError, error, isLoading }] =
    reportsAPI.useUpdateReportCommentMutation()

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setComment(e.target.value)
  }

  const handleUpdate = () => {
    fetchUpdateReportComment({ comment, reportId: String(reportId) })
  }

  // Изменение комментария прошло успешно
  useEffect(() => {
    if (isSuccess && data) {
      notification.open({
        message: t('notifications.comment.success'),
        icon: <CheckOutlined style={{ color: '#52c41a' }} />,
      })
      setComment(data.comment)
    }
  }, [isSuccess, data])

  // Изменение комментария прошло с ошибкой
  useEffect(() => {
    if (isError) {
      notification.open({
        message: t('notifications.comment.error'),
        description: (error as any).data.message,
        icon: <CloseCircleOutlined style={{ color: '#c4391a' }} />,
      })
    }
  }, [isError, error])

  return (
    <div style={{ display: 'flex', alignItems: 'end', gap: 12 }}>
      <Input.TextArea
        style={{ width: 500 }}
        rows={4}
        showCount
        onChange={handleChange}
        value={comment}
        placeholder='Add comment'
      />
      <Tooltip title='Update'>
        <Button
          disabled={isLoading}
          type='primary'
          onClick={handleUpdate}
          shape='circle'
          icon={<CheckOutlined />}
        />
      </Tooltip>
    </div>
  )
}
