import { Checkbox, Form, Row, Col } from 'antd'

interface MarksProps {
  disabled?: boolean
}

export const Marks = ({ disabled }: MarksProps) => (
  <Row>
    <Col span={8}>
      <Form.Item name='is_only_on_cherrity' valuePropName='checked'>
        <Checkbox disabled={disabled}>Is only on cherrity</Checkbox>
      </Form.Item>
    </Col>
    <Col span={8}>
      <Form.Item name='is_cherrity_choice' valuePropName='checked'>
        <Checkbox disabled={disabled}>Is cherrity choice</Checkbox>
      </Form.Item>
    </Col>
    <Col span={8}>
      <Form.Item name='is_urgently' valuePropName='checked'>
        <Checkbox disabled={disabled}>Is urgently</Checkbox>
      </Form.Item>
    </Col>
  </Row>
)
