import { Form, Input } from 'antd'

export const AuthorizedPerson = () => {
  return (
    <>
      <Form.Item name={['curator', 'full_name']} label='Full name' rules={[{ required: true }]}>
        <Input disabled />
      </Form.Item>
      <Form.Item name={['curator', 'email']} label='Email' rules={[{ required: true }]}>
        <Input disabled />
      </Form.Item>
      <Form.Item name={['curator', 'phone']} label='Phone' rules={[{ required: true }]}>
        <Input disabled />
      </Form.Item>
    </>
  )
}
