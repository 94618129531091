import { DatePicker, Form, Input, Select, Row, Col } from 'antd'

import { ErrorFeedback } from 'components/Utilities/ErrorFeedback'
import { Loader } from 'components/Utilities/Loader'
import { I_CampaignForm, I_ReasonForBlocking } from 'models/campaign'
import { dictionariesAPI } from 'services/dictionaries'
import { getCountries, getStates } from 'utils/dictionaries/locations'
import { getOwnerSubjects, getParentSubjects } from 'utils/dictionaries/subjects'
import { getOwnerTargets } from 'utils/dictionaries/targets'
import { E_FormatDate, disabledDateBeforeTodayAndToday } from 'utils/helpers/date'

interface GeneralInfoProps {
  withStatus?: boolean
  disabled?: boolean
  reasonsForBlocking?: I_ReasonForBlocking
  stateValue: string[]
}

export const GeneralInfo = ({
  withStatus = false,
  disabled = false,
  stateValue,
}: GeneralInfoProps) => {
  const { data: campaignStatuses, isLoading: isCampaignStatusesLoading } =
    dictionariesAPI.useGetCampaignStatusesQuery()
  const { data: subjects, isLoading: isSubjectsLoading } = dictionariesAPI.useGetSubjectsQuery()
  const { data: targets, isLoading: isTargetsLoading } = dictionariesAPI.useGetTargetsQuery()
  const { data: locations, isLoading: isLocationsLoading } = dictionariesAPI.useGetLocationsQuery()

  if (isCampaignStatusesLoading || isSubjectsLoading || isTargetsLoading || isLocationsLoading)
    return <Loader relative />

  if (campaignStatuses && subjects && targets && locations) {
    const ownerSubjects = getOwnerSubjects(subjects)
    const parentSubjects = getParentSubjects(subjects)
    const ownerTargets = getOwnerTargets(targets)
    const parentTargets = getParentSubjects(targets)
    const countries = getCountries(locations)
    const states = getStates(locations)

    return (
      <>
        <Row>
          <Col span={11}>
            <Form.Item name='name' label='Name' rules={[{ required: true }]}>
              <Input disabled={disabled} />
            </Form.Item>
          </Col>
          <Col span={6} offset={1}>
            <Form.Item name='closed_at' label='Campaign closing date'>
              <DatePicker
                disabled={disabled}
                disabledDate={disabledDateBeforeTodayAndToday}
                style={{ width: '100%' }}
                format={E_FormatDate.shortYear}
                placeholder='Select date'
              />
            </Form.Item>
          </Col>
        </Row>
        {withStatus && (
          <>
            <Form.Item name='status_id' label='Campaign status' rules={[{ required: true }]}>
              <Select allowClear disabled={disabled}>
                {campaignStatuses.statuses.map((status) => (
                  <Select.Option key={status.id} value={status.id}>
                    {status.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues: I_CampaignForm, currentValues: I_CampaignForm) =>
                prevValues.status_id !== currentValues.status_id
              }
            >
              {({ getFieldValue }: any) =>
                getFieldValue('status_id') === 'suspended' ||
                getFieldValue('status_id') === 'blocked' ? (
                  <Form.Item name='reason_for_blocking' label='Reason' rules={[{ required: true }]}>
                    <Input disabled={disabled} />
                  </Form.Item>
                ) : null
              }
            </Form.Item>
          </>
        )}
        <Row>
          <Col span={11}>
            <Form.Item name={['address', 'country']} label='Country' rules={[{ required: true }]}>
              <Select allowClear disabled={disabled}>
                {countries.map((country) => (
                  <Select.Option key={country.id} value={country.id}>
                    {country.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12} offset={1}>
            <Form.Item name={['address', 'state']} label='State' rules={[{ required: true }]}>
              <Select optionFilterProp='children' mode='multiple' allowClear disabled={disabled}>
                {states.map((state) => (
                  <Select.Option key={state.id} value={state.id}>
                    {state.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          {stateValue?.length === 1 && (
            <>
              <Col span={11}>
                <Form.Item name={['address', 'city']} label='City'>
                  <Input disabled={disabled} />
                </Form.Item>
              </Col>
              <Col span={12} offset={1}>
                <Form.Item name={['address', 'address']} label='Address'>
                  <Input disabled={disabled} />
                </Form.Item>
              </Col>
            </>
          )}
        </Row>
        <Form.Item name='subject_id' label='Who for' rules={[{ required: true }]}>
          <Select mode='multiple' optionFilterProp='children' disabled={disabled}>
            {ownerSubjects.map((subject) => (
              <Select.Option key={subject.id} value={subject.id}>
                {subject.name}
              </Select.Option>
            ))}
            {parentSubjects.map((subject) => (
              <Select.OptGroup key={subject.id} label={subject.name}>
                {subject.parentObjects.map((child) => (
                  <Select.Option key={child.id} value={child.id}>
                    {child.name}
                  </Select.Option>
                ))}
              </Select.OptGroup>
            ))}
          </Select>
        </Form.Item>

        <Form.Item name='target_id' label='Theme' rules={[{ required: true }]}>
          <Select mode='multiple' optionFilterProp='children' disabled={disabled}>
            {ownerTargets.map((target) => (
              <Select.Option key={target.id} value={target.id}>
                {target.name}
              </Select.Option>
            ))}
            {parentTargets.map((target) => (
              <Select.OptGroup key={target.id} optionFilterProp='children' label={target.name}>
                {target.parentObjects.map((child) => (
                  <Select.Option key={child.id} value={child.id}>
                    {child.name}
                  </Select.Option>
                ))}
              </Select.OptGroup>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name='description' label='Description' rules={[{ required: true }]}>
          <Input.TextArea showCount disabled={disabled} />
        </Form.Item>
      </>
    )
  }

  return <ErrorFeedback />
}
