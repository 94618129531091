import { FieldError } from 'rc-field-form/es/interface'

import { sectionsNames } from './data'

export const errorsToStepsStatus = (fields: FieldError[]): boolean[] => {
  const errors: boolean[] = []

  sectionsNames.forEach((sectionNames) => {
    const isSectionError =
      fields.findIndex((field) => sectionNames.includes(field.name[0] as string) || false) < 0
    errors.push(isSectionError)
  })

  return errors
}
