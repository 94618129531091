import { Breadcrumb } from 'antd'

import { Donuts } from './extensions/Donuts'
import { Finance } from './extensions/Finance'

import { Brick } from 'components/Utilities/Brick'
import { ErrorFeedback } from 'components/Utilities/ErrorFeedback'
import { Loader } from 'components/Utilities/Loader'
import { t } from 'languages'
import { statisticsAPI } from 'services/statistics'

export const StatsPage = () => {
  const { data: statisticsData, isLoading: isStatisticsLoading } =
    statisticsAPI.useGetStatisticsQuery()

  if (isStatisticsLoading) return <Loader />

  if (statisticsData) {
    const { finance_statistics, fund_statistics, campaign_statistics, report_statistics } =
      statisticsData

    return (
      <div>
        <Breadcrumb>
          <Breadcrumb.Item>{t('breadcrumbs.stats')}</Breadcrumb.Item>
        </Breadcrumb>
        <div className='site-layout-content'>
          <Finance financeStatistics={finance_statistics} />
          <Brick />
          <Donuts
            fundStatistics={fund_statistics}
            campaignStatistics={campaign_statistics}
            reportStatistics={report_statistics}
          />
        </div>
      </div>
    )
  }

  return <ErrorFeedback />
}
