import { Divider, Typography, Row, Col } from 'antd'
import ReactApexChart from 'react-apexcharts'

import {
  fundsOptions,
  totalFundraiserOptions,
  activeFundraiserOptions,
  reportsOptions,
  getFundsSeries,
  getReportsSeries,
  getTotalFundraiserSeries,
  getActiveFundraiserSeries,
} from './data'

import { Brick } from 'components/Utilities/Brick'
import { t } from 'languages'
import { I_CampaignStatistics, I_FundStatistics, I_ReportStatistics } from 'models/statistics'

interface I_DonutsProps {
  fundStatistics: I_FundStatistics
  campaignStatistics: I_CampaignStatistics
  reportStatistics: I_ReportStatistics
}

export const Donuts = ({ fundStatistics, campaignStatistics, reportStatistics }: I_DonutsProps) => {
  const fundsSeries = getFundsSeries(fundStatistics)
  const reportsSeries = getReportsSeries(reportStatistics)
  const totalCampaignsSeries = getTotalFundraiserSeries(campaignStatistics)
  const activeCampaignsSeries = getActiveFundraiserSeries(campaignStatistics)

  return (
    <>
      <Row gutter={[24, 24]}>
        <Col>
          <Divider orientation='left' orientationMargin='0'>
            <Typography.Title level={3}>{t('stats.donuts.ngos.title')}</Typography.Title>
          </Divider>
          <ReactApexChart
            width={500}
            height={500}
            options={fundsOptions}
            series={fundsSeries}
            type='donut'
          />
        </Col>
        <Col>
          <Divider orientation='left'>
            <Typography.Title level={3}>{t('stats.donuts.reports.title')}</Typography.Title>
          </Divider>
          <ReactApexChart
            width={450}
            height={450}
            options={reportsOptions}
            series={reportsSeries}
            type='donut'
          />
        </Col>
      </Row>
      <Brick />

      <Row gutter={[24, 24]}>
        <Col>
          <Divider orientation='left'>
            <Typography.Title level={3}>
              {t('stats.donuts.totalFundraisers.title')}
            </Typography.Title>
          </Divider>
          <ReactApexChart
            width={500}
            height={500}
            options={totalFundraiserOptions}
            series={totalCampaignsSeries}
            type='donut'
          />
        </Col>
        <Col>
          <Divider orientation='left'>
            <Typography.Title level={3}>
              {t('stats.donuts.activeFundraisers.title')}
            </Typography.Title>
          </Divider>
          <ReactApexChart
            width={450}
            height={450}
            options={activeFundraiserOptions}
            series={activeCampaignsSeries}
            type='donut'
          />
        </Col>
      </Row>
    </>
  )
}
