import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons'
import { Button, Form, Input, Space } from 'antd'

interface ContactsProps {
  disabled?: boolean
}

export const Contacts = ({ disabled }: ContactsProps) => (
  <Form.List name='contact_persons'>
    {(fields, { add, remove }) => (
      <>
        {fields.map(({ key, name, ...restField }) => (
          <Space key={key} style={{ alignItems: 'flex-start', width: '100%' }}>
            <Form.Item
              {...restField}
              name={[name, 'full_name']}
              rules={[{ required: true, message: 'Please enter Full name' }]}
            >
              <Input placeholder='Full name' disabled={disabled} />
            </Form.Item>
            <Form.Item {...restField} name={[name, 'position']}>
              <Input placeholder='Position' disabled={disabled} />
            </Form.Item>
            <Form.Item {...restField} name={[name, 'phone']}>
              <Input placeholder='Phone number' disabled={disabled} />
            </Form.Item>
            <Form.Item {...restField} name={[name, 'email']}>
              <Input placeholder='Email' disabled={disabled} />
            </Form.Item>
            <MinusCircleOutlined onClick={() => (disabled ? null : remove(name))} />
          </Space>
        ))}
        <Form.Item>
          <Button
            disabled={disabled}
            type='primary'
            onClick={() => add()}
            block
            icon={<PlusOutlined />}
          >
            Add contact
          </Button>
        </Form.Item>
      </>
    )}
  </Form.List>
)
