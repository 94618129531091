import { createApi } from '@reduxjs/toolkit/query/react'

import { baseQueryWithReAuth } from '../utils'

import { I_Donation } from 'models/donation'

export const refundAPI = createApi({
  reducerPath: 'refundAPI',
  baseQuery: baseQueryWithReAuth,
  endpoints: (build) => ({
    getDonation: build.query<{ donation: I_Donation }, string>({
      query: (donationId) => ({
        url: `/donation/${donationId}`,
      }),
    }),
    returnDonation: build.query<{ donation: I_Donation }, string>({
      query: (donationId) => ({
        url: `/donation/returned/${donationId}`,
      }),
    }),
  }),
})
