import { createApi } from '@reduxjs/toolkit/query/react'

import { baseQueryWithReAuth } from '../utils'

import { I_Statistics } from 'models/statistics'

export const statisticsAPI = createApi({
  reducerPath: 'statisticsAPI',
  baseQuery: baseQueryWithReAuth,
  endpoints: (build) => ({
    getStatistics: build.query<I_Statistics, void>({
      query: () => ({
        url: '/statistics',
      }),
    }),
  }),
})
