import { Breadcrumb, Table } from 'antd'
import { useNavigate } from 'react-router-dom'

import { getColumns } from './data'

import { ErrorFeedback } from 'components/Utilities/ErrorFeedback'
import { Loader } from 'components/Utilities/Loader'
import { t } from 'languages'
import { campaignsAPI } from 'services/campaigns'
import { dictionariesAPI } from 'services/dictionaries'
import { formatToDataSource } from 'utils/helpers/table'

export const DataFundraiserPage = () => {
  const navigate = useNavigate()

  // Получение всех сборов
  const { data: campaignsData, isLoading: isCampaignsLoading } = campaignsAPI.useGetCampaignsQuery()

  // Получение статусов сбора
  const { data: campaignStatuses, isLoading: isCampaignStatusesLoading } =
    dictionariesAPI.useGetCampaignStatusesQuery()

  if (isCampaignsLoading || isCampaignStatusesLoading) return <Loader />

  if (campaignsData && campaignStatuses) {
    const dataTable = formatToDataSource(campaignsData.campaigns)
    const columnsTable = getColumns(campaignStatuses.statuses)

    return (
      <div>
        <Breadcrumb>
          <Breadcrumb.Item>{t('breadcrumbs.dataCampaigns')}</Breadcrumb.Item>
        </Breadcrumb>
        <div className='site-layout-content'>
          <Table
            columns={columnsTable}
            dataSource={dataTable}
            onRow={(record) => {
              return {
                onClick: () => {
                  navigate(
                    `/ngos/${String(record.fund_internal_id)}/fundraiser/${String(
                      record.internal_id,
                    )}`,
                  )
                },
              }
            }}
          />
        </div>
      </div>
    )
  }

  return <ErrorFeedback />
}
