export enum E_Routes {
  ngos = '/ngos',
  ngosNew = '/ngos/new',
  ngo = '/ngos/:fundId',
  fundraiser = '/ngos/:fundId/fundraiser/:campaignId',
  fundraiserNew = '/ngos/:fundId/fundraiser/new',
  report = '/ngos/:fundId/fundraiser/:campaignId/report',
  reportClose = '/ngos/:fundId/fundraiser/:campaignId/report/close',
  complaintsReports = '/complaints/reports',
  complaintsFundraisers = '/complaints/fundraisers',
  dataReports = '/data/reports',
  dataFundraisers = '/data/fundraiser',
  stats = '/stats',
  notifications = '/notifications',
  refund = '/refund',
}
