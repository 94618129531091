import {
  SnippetsOutlined,
  ExceptionOutlined,
  ContactsOutlined,
  SolutionOutlined,
  BankOutlined,
  CommentOutlined,
  ContainerOutlined,
} from '@ant-design/icons'
import { ReactElement } from 'react'

export type T_Step = { title: string; icon: ReactElement }

export const steps: T_Step[] = [
  { title: 'fundForm.steps.generalInfo', icon: <SnippetsOutlined /> },
  { title: 'fundForm.steps.legalInfo', icon: <ExceptionOutlined /> },
  { title: 'fundForm.steps.fundManager', icon: <ContactsOutlined /> },
  { title: 'fundForm.steps.authorizedPerson', icon: <SolutionOutlined /> },
  { title: 'fundForm.steps.bankDetails', icon: <BankOutlined /> },
  { title: 'fundForm.steps.socialInfo', icon: <CommentOutlined /> },
  { title: 'fundForm.steps.personalInfo', icon: <ContainerOutlined /> },
]

export const initialStepsStatus = [true, true, true, true, true, true, true]

export const sectionsNames = [
  // general info
  ['name', 'founding_date', 'actual_address', 'website', 'email', 'phone', 'carousel_photos'],

  // legal info
  ['legal_info'],

  // fund manager
  ['head_person'],

  // authorized person
  ['authorized_person'],

  // bank details
  [],

  // social info
  ['brand_photo', 'description'],

  // personal info
  ['request_author'],
]
