import { Form, Input, Upload, Modal } from 'antd'
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface'
import { useEffect, useState } from 'react'

import { UploadButton } from 'components/UploadButton'
import { filesAPI } from 'services/files'
import {
  beforeImageUpload,
  customRequestUpload,
  getBase64,
  normalizeImages,
  uploadImageAcceptFiles,
  uploadImageExtraText,
} from 'utils/forms/fileUploader'

interface FundManagerProps {
  disabled?: boolean
  fileList?: any
}

export const FundManager = ({ disabled = false, fileList }: FundManagerProps) => {
  const [fetchUpload] = filesAPI.useUploadPublicFileMutation()

  const [uploadProcess, setUploadProcess] = useState({
    previewVisible: false,
    previewImage: '',
    previewTitle: '',
    fileList: fileList || [],
  })

  const handlePreview = async (file: any) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }
    setUploadProcess((prev) => ({
      ...prev,
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
    }))
  }

  const handleClosePreview = () => setUploadProcess((prev) => ({ ...prev, previewVisible: false }))

  const handleChangePhoto = (info: UploadChangeParam<UploadFile<any>>) => {
    setUploadProcess((prev) => ({ ...prev, fileList: info.fileList }))
  }

  useEffect(() => {
    if (uploadProcess.fileList.length || fileList) {
      setUploadProcess((prev) => ({ ...prev, fileList }))
    }
  }, [fileList])

  return (
    <>
      <Form.Item name={['head_person', 'full_name']} label='Full name' rules={[{ required: true }]}>
        <Input disabled={disabled} />
      </Form.Item>
      <Form.Item
        name={['head_person', 'email']}
        label='Email'
        rules={[{ required: true, type: 'email' }]}
      >
        <Input disabled={disabled} />
      </Form.Item>

      <Form.Item
        name={['head_person', 'photo']}
        label='Photo'
        valuePropName='fileList'
        getValueFromEvent={normalizeImages}
        rules={[{ required: true }]}
        extra={uploadImageExtraText}
      >
        <Upload
          accept={uploadImageAcceptFiles}
          listType='picture-card'
          onPreview={handlePreview}
          customRequest={(options) => customRequestUpload(options, fetchUpload)}
          onChange={handleChangePhoto}
          beforeUpload={beforeImageUpload}
          disabled={disabled}
        >
          {uploadProcess.fileList.length >= 1 ? null : <UploadButton />}
        </Upload>
      </Form.Item>
      <Modal
        visible={uploadProcess.previewVisible}
        title={uploadProcess.previewTitle}
        footer={null}
        onCancel={handleClosePreview}
      >
        <img alt='preview main photo' style={{ width: '100%' }} src={uploadProcess.previewImage} />
      </Modal>
    </>
  )
}
