import { CheckOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { Button, Modal, notification } from 'antd'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'

import { t } from 'languages'
import { campaignsAPI } from 'services/campaigns'

interface ReportButtonProps {
  status: string
  campaignId: string
}

export const ReportButton = ({ status, campaignId }: ReportButtonProps) => {
  const [
    fetchCloseCampaign,
    {
      data: campaignData,
      isSuccess: isCampaignSuccess,
      isError: isCampaignError,
      isLoading: isCampaignLoading,
      error: campaignError,
    },
  ] = campaignsAPI.useCloseCampaignMutation()

  const handleCloseCampaign = () => {
    Modal.confirm({
      title: t('modal.confirm.closeCampaign.title'),
      icon: <ExclamationCircleOutlined />,
      content: t('modal.confirm.closeCampaign.content'),
      okText: t('modal.confirm.closeCampaign.ok'),
      cancelText: t('modal.confirm.closeCampaign.cancel'),
      maskClosable: true,
      onOk: () => {
        fetchCloseCampaign({ campaignId })
      },
    })
  }

  // Статус изменился успешно
  useEffect(() => {
    if (isCampaignSuccess && campaignData) {
      notification.open({
        message: t('notifications.campaign.closeSuccess'),
        icon: <CheckOutlined style={{ color: '#52c41a' }} />,
      })
    }
  }, [isCampaignSuccess, campaignData])

  // Статус изменился с ошибкой
  useEffect(() => {
    if (isCampaignError) {
      notification.open({
        message: t('notifications.campaign.closeError') + campaignError,
        icon: <CheckOutlined style={{ color: '#52c41a' }} />,
      })
    }
  }, [isCampaignError, campaignError])

  if (status === 'closed_preparing_report') {
    return (
      <Link to={`report/close`}>
        <Button size='large' type='primary'>
          {t('fundraiser.actions.publishReport')}
        </Button>
      </Link>
    )
  }
  if (status === 'closed_report_ready') {
    return (
      <Link to={`report`}>
        <Button size='large' type='primary'>
          {t('fundraiser.actions.viewReport')}
        </Button>
      </Link>
    )
  }

  if (status !== 'blocked') {
    return (
      <Button
        disabled={isCampaignLoading}
        onClick={handleCloseCampaign}
        size='large'
        type='primary'
      >
        {t('fundraiser.actions.closeFundraiser')}
      </Button>
    )
  }

  return <></>
}
