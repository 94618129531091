import moment from 'moment'

import { I_Fund, I_FundForm } from 'models/fund'
import { LocalStorage } from 'utils/helpers/localStorage'

const executeAccessToken = (url: string): string => url.split('?')[0]

const assignAccessToken = (url: string): string => {
  const accessToken = LocalStorage.getUser() || ''
  return `${url}?access-token=${accessToken}`
}

export const fundToForm = (fund: I_Fund): I_FundForm => {
  const {
    founding_date,
    brand_photo,
    legal_info,
    head_person,
    authorized_person,
    carousel_photos,
    ...fields
  } = fund

  return {
    ...fields,
    legal_info: {
      ...legal_info,
      FCRA_validity_period: moment(legal_info.FCRA_validity_period),
      certificate_validity_period: moment(legal_info.certificate_validity_period),
      it_pan_document: [
        {
          ...legal_info.it_pan_document,
          url: assignAccessToken(legal_info.it_pan_document.url),
        },
      ],
      registration_document: [
        {
          ...legal_info.registration_document,
          url: assignAccessToken(legal_info.registration_document.url),
        },
      ],
      fcra_document: legal_info.fcra_document
        ? [
            {
              ...legal_info.fcra_document,
              url: assignAccessToken(legal_info.fcra_document.url),
            },
          ]
        : [],
      gsn_certificate: legal_info.gsn_certificate
        ? [
            {
              ...legal_info.gsn_certificate,
              url: assignAccessToken(legal_info.gsn_certificate.url),
            },
          ]
        : [],
      other_documents: legal_info.other_documents.map((document) => ({
        ...document,
        url: assignAccessToken(document.url),
      })),
    },
    carousel_photos: carousel_photos.map((photo, index) => ({ ...photo, uid: String(index) })),
    brand_photo: [{ ...brand_photo, uid: '1' }],
    head_person: { ...head_person, photo: [{ url: head_person.photo.url, uid: '6' }] },
    authorized_person: { ...authorized_person, photo: [{ ...authorized_person.photo, uid: '7' }] },
    founding_date: moment(founding_date),
    request_author: {
      personPhoto: [{ uid: '2', url: assignAccessToken(fund.request_author.personPhoto.url) }],
      passportPhoto: [{ uid: '3', url: assignAccessToken(fund.request_author.passportPhoto.url) }],
      personPassportPhoto: [
        { uid: '4', url: assignAccessToken(fund.request_author.personPassportPhoto.url) },
      ],
    },
  }
}

export const formToFund = (form: I_FundForm): I_Fund => {
  const { request_author, legal_info, head_person, authorized_person, ...fields } = form

  console.log('form', form)

  return {
    ...fields,
    legal_info: {
      ...legal_info,
      it_pan_document: {
        ...legal_info.it_pan_document[0],
        url: executeAccessToken(legal_info.it_pan_document[0].url),
      },
      registration_document: {
        ...legal_info.registration_document[0],
        url: executeAccessToken(legal_info.registration_document[0].url),
      },
      fcra_document: legal_info.fcra_document?.length
        ? {
            ...legal_info.fcra_document[0],
            url: executeAccessToken(legal_info.fcra_document[0].url),
          }
        : null,
      gsn_certificate: {
        ...legal_info.gsn_certificate[0],
        url: executeAccessToken(legal_info.gsn_certificate[0].url),
      },
      other_documents: legal_info.other_documents?.length
        ? legal_info.other_documents.map((document) => ({
            ...document,
            url: executeAccessToken(document.url),
          }))
        : [],
    },
    head_person: { ...head_person, photo: head_person.photo[0] },
    authorized_person: { ...authorized_person, photo: authorized_person.photo[0] },
    brand_photo: form.brand_photo[0],
    request_author: {
      personPhoto: { url: executeAccessToken(request_author.personPhoto[0].url) },
      passportPhoto: { url: executeAccessToken(request_author.passportPhoto[0].url) },
      personPassportPhoto: { url: executeAccessToken(request_author.personPassportPhoto[0].url) },
    },
  }
}
