import throttle from 'lodash.throttle'
import { Dispatch, RefObject, SetStateAction, useEffect } from 'react'

export const useScrollSteps = (
  ref: RefObject<HTMLDivElement>,
  setCurrentStep: Dispatch<SetStateAction<number>>,
  isHandled: boolean,
) => {
  useEffect(() => {
    // Изменение шага по событию скролла
    const handleScroll = throttle(() => {
      if (ref.current) {
        const windowScrollY = Math.ceil(window.scrollY + 128)
        const elements = Array.from(ref.current.children) as HTMLElement[]

        elements.map((element, index) => {
          const { offsetTop, offsetHeight } = element
          if (offsetTop <= windowScrollY && offsetHeight + offsetTop > windowScrollY) {
            if (window.scrollY + window.innerHeight === document.documentElement.scrollHeight) {
              setCurrentStep(elements.length - 1)
              return
            }
            setCurrentStep(index)
          }
        })
      }
    }, 50)
    if (!isHandled) {
      window.addEventListener('scroll', handleScroll)
    }
    return () => window.removeEventListener('scroll', handleScroll)
  }, [isHandled])
}
