import { Breadcrumb, Table } from 'antd'
import { useNavigate } from 'react-router-dom'

import { getColumns } from './data'

import { ErrorFeedback } from 'components/Utilities/ErrorFeedback'
import { Loader } from 'components/Utilities/Loader'
import { t } from 'languages'
import { reportsAPI } from 'services/reports'
import { formatToDataSource } from 'utils/helpers/table'

export const DataReportsPage = () => {
  const { data: reportsData, isLoading: isReportsLoading } = reportsAPI.useGetReportsQuery()
  const navigate = useNavigate()

  if (isReportsLoading) return <Loader />

  if (reportsData) {
    const dataTable = formatToDataSource(reportsData.reposts)
    return (
      <div>
        <Breadcrumb>
          <Breadcrumb.Item>{t('breadcrumbs.dataReports')}</Breadcrumb.Item>
        </Breadcrumb>
        <div className='site-layout-content'>
          <Table
            columns={getColumns()}
            dataSource={dataTable}
            onRow={(record) => {
              return {
                onClick: () => {
                  navigate(
                    `/ngos/${String(record.fund_internal_id)}/fundraiser/${String(
                      record.campaign_internal_id,
                    )}/report`,
                  )
                },
              }
            }}
          />
        </div>
      </div>
    )
  }

  return <ErrorFeedback />
}
