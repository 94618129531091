import {
  ExclamationCircleOutlined,
  CheckCircleOutlined,
  CheckOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons'
import { Table, Modal, notification } from 'antd'
import { useEffect } from 'react'

import { getColumns } from './data'

import { ErrorFeedback } from 'components/Utilities/ErrorFeedback'
import { Loader } from 'components/Utilities/Loader'
import { t } from 'languages'
import { T_CampaignId } from 'models/campaign'
import { T_CampaignReviewId } from 'models/review'
import { campaignsAPI } from 'services/campaigns'
import { formatToDataSource, getOnlyComplaint } from 'utils/helpers/table'

export const ComplaintsTable = () => {
  const { data: reviewsData, isLoading: isReviewsLoading } =
    campaignsAPI.useGetCampaignsReviewsQuery()

  const [
    fetchRemoveReview,
    { isSuccess: isRemoveSuccess, isError: isRemoveError, error: removeError },
  ] = campaignsAPI.useDeleteCampaignReviewMutation()

  const [
    fetchApproveReview,
    { isSuccess: isToggleSuccess, isError: isToggleError, error: toggleError },
  ] = campaignsAPI.useApproveCampaignReviewComplaintMutation()

  const handleRemove = (reviewId: T_CampaignReviewId) => {
    Modal.confirm({
      title: t('modal.confirm.removeComment.title'),
      icon: <ExclamationCircleOutlined />,
      content: t('modal.confirm.removeComment.content'),
      okText: t('modal.confirm.removeComment.ok'),
      cancelText: t('modal.confirm.removeComment.cancel'),
      maskClosable: true,
      onOk: () => {
        fetchRemoveReview(reviewId)
      },
    })
  }

  const handleApprove = (reviewId: T_CampaignReviewId, campaignId: T_CampaignId) => {
    Modal.confirm({
      title: t('modal.confirm.approveComment.title'),
      icon: <CheckCircleOutlined />,
      content: t('modal.confirm.approveComment.content'),
      okText: t('modal.confirm.approveComment.ok'),
      cancelText: t('modal.confirm.approveComment.cancel'),
      maskClosable: true,
      onOk: () => {
        fetchApproveReview({ reviewId, campaignId })
      },
    })
  }

  // Удаление обзора прошло успешно
  useEffect(() => {
    if (isRemoveSuccess) {
      notification.open({
        message: t('notifications.reviews.successDeleted'),
        icon: <CheckOutlined style={{ color: '#52c41a' }} />,
      })
    }
  }, [isRemoveSuccess])

  // Удаление обзора произошло с ошибкой
  useEffect(() => {
    if (isRemoveError) {
      notification.open({
        message: t('notifications.reviews.errorDeleted'),
        description: (removeError as any)?.data?.message,
        icon: <CloseCircleOutlined style={{ color: '#c4391a' }} />,
      })
    }
  }, [isRemoveError, removeError])

  // Апрув обзора прошло успешно
  useEffect(() => {
    if (isToggleSuccess) {
      notification.open({
        message: t('notifications.reviews.successApprove'),
        icon: <CheckOutlined style={{ color: '#52c41a' }} />,
      })
    }
  }, [isToggleSuccess])

  // Апрув обзора произошло с ошибкой
  useEffect(() => {
    if (isToggleError) {
      notification.open({
        message: t('notifications.reviews.errorApprove'),
        description: (toggleError as any)?.data?.message,
        icon: <CloseCircleOutlined style={{ color: '#c4391a' }} />,
      })
    }
  }, [isToggleError, toggleError])

  if (isReviewsLoading) {
    return <Loader />
  }

  if (reviewsData) {
    const dataTable = formatToDataSource(getOnlyComplaint(reviewsData.reviews))
    return (
      <Table
        columns={getColumns(handleApprove, handleRemove)}
        dataSource={dataTable}
        expandable={{
          expandedRowRender: (record) => <p style={{ margin: 0 }}>{record.text}</p>,
        }}
      />
    )
  }

  return <ErrorFeedback relative />
}
