import { Space } from 'antd'
import moment from 'moment'

import { t } from 'languages'
import { I_Option } from 'models/dictionaries'
import { I_FundRecord } from 'models/fund'
import { getStatusName } from 'utils/dictionaries/statuses'
import { E_FormatDate } from 'utils/helpers/date'

export const getColumns = (statuses: I_Option[]) => [
  {
    title: t('ngos.table.columns.id'),
    dataIndex: 'internal_id',
    sorter: (a: I_FundRecord, b: I_FundRecord) => a.internal_id - b.internal_id,
  },
  {
    title: t('ngos.table.columns.name'),
    dataIndex: 'name',
    sorter: (a: I_FundRecord, b: I_FundRecord) => a.name.localeCompare(b.name),
  },

  {
    title: t('ngos.table.columns.status'),
    dataIndex: 'status_id',
    filters: statuses.map((status) => ({
      text: status.name,
      value: status.id,
    })),
    onFilter: (value: any, record: I_FundRecord) => record.status_id.startsWith(value),
    filterSearch: true,
    render: (statusId: string) => getStatusName(statuses, statusId),
  },

  {
    title: t('ngos.table.columns.created'),
    dataIndex: 'created_at',
    sorter: (a: I_FundRecord, b: I_FundRecord) =>
      moment(a.created_at).unix() - moment(b.created_at).unix(),
    render: (created_at: string) => (
      <Space size='middle'>{moment(created_at).format(E_FormatDate.default)}</Space>
    ),
  },
  {
    title: t('ngos.table.columns.fundraisingCount'),
    dataIndex: 'campaigns_count',
    sorter: (a: I_FundRecord, b: I_FundRecord) => a.campaigns_count - b.campaigns_count,
  },
  {
    title: t('ngos.table.columns.collected'),
    dataIndex: 'money_count',
    sorter: (a: I_FundRecord, b: I_FundRecord) => a.money_count - b.money_count,
  },
  {
    title: t('ngos.table.columns.rating'),
    dataIndex: 'rating',
    sorter: (a: I_FundRecord, b: I_FundRecord) => a.rating.rating - b.rating.rating,
    render: (_: string, record: I_FundRecord) => (
      <Space size='middle'>{record.rating.rating}</Space>
    ),
  },
  {
    title: t('ngos.table.columns.pan'),
    dataIndex: 'it_pan_no',
    sorter: (a: I_FundRecord, b: I_FundRecord) =>
      a.legal_info.it_pan_no.localeCompare(b.legal_info.it_pan_no),
    render: (_: string, record: I_FundRecord) => (
      <Space size='middle'>{record.legal_info.it_pan_no}</Space>
    ),
  },
]
