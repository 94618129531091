import { Form, Select, Row, Col, InputNumber } from 'antd'

interface HelpTypeProps {
  disabled?: boolean
}

export const HelpType = ({ disabled = false }: HelpTypeProps) => {
  return (
    <Row>
      <Col span={6}>
        <Form.Item name='type_of_help' label='Type of help' rules={[{ required: true }]}>
          <Select disabled={disabled}>
            <Select.Option value='financial'>Financial</Select.Option>
          </Select>
        </Form.Item>
      </Col>
      <Col span={6} offset={1}>
        <Form.Item name='financial_target' label='Financial target' rules={[{ required: true }]}>
          <InputNumber
            disabled={disabled}
            style={{ width: '100%' }}
            formatter={(value) => `₹ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(value) => value!.replace(/₹\s?|(,*)/g, '')}
            maxLength={14}
          />
        </Form.Item>
      </Col>
    </Row>
  )
}
