import { createApi } from '@reduxjs/toolkit/query/react'

import { baseQueryWithReAuth } from '../utils'

import { I_FundCheckResult } from 'models/check'
import { I_CommentResponse, I_Fund, I_FundResponse, T_FundId } from 'models/fund'

export const fundsAPI = createApi({
  reducerPath: 'fundsAPI',
  baseQuery: baseQueryWithReAuth,
  tagTypes: ['fund'],
  endpoints: (build) => ({
    getFunds: build.query<{ funds: I_FundResponse[] }, void>({
      query: () => ({
        url: '/funds',
      }),
    }),

    getFund: build.query<{ fund: I_FundResponse; fundId: T_FundId }, string>({
      query: (fundId) => ({
        url: `/funds/${fundId}`,
      }),
      providesTags: ['fund'],
    }),

    createFund: build.mutation<{ fund: I_FundResponse }, I_Fund>({
      query: (payload) => ({
        url: `/funds`,
        method: 'POST',
        body: payload,
      }),
    }),

    updateFund: build.mutation<{ fund: I_FundResponse }, { fund: I_Fund; fundId: T_FundId }>({
      query: (payload) => ({
        url: `/funds/${payload.fundId}`,
        method: 'PUT',
        body: payload.fund,
      }),
      invalidatesTags: ['fund'],
    }),

    updateFundComment: build.mutation<I_CommentResponse, { comment: string; fundId: T_FundId }>({
      query: (payload) => ({
        url: `/funds/${payload.fundId}/comment`,
        method: 'PUT',
        body: { comment: payload.comment },
      }),
      invalidatesTags: ['fund'],
    }),

    fundCheck: build.mutation<{ result: I_FundCheckResult }, T_FundId>({
      query: (fundId) => ({
        url: `/funds/${fundId}/check`,
        method: 'GET',
      }),
    }),
  }),
})
