import { Breadcrumb } from 'antd'
import { Link, useParams } from 'react-router-dom'

import './styles.scss'

import { CampaignNewForm } from 'components/Forms'
import { ErrorFeedback } from 'components/Utilities/ErrorFeedback'
import { Loader } from 'components/Utilities/Loader'
import { t } from 'languages'
import { fundsAPI } from 'services/funds'

export const FundraiserNewPage = () => {
  const params = useParams()

  const { data: fundData, isLoading: isFundLoading } = fundsAPI.useGetFundQuery(params.fundId!)

  if (isFundLoading) return <Loader />

  if (fundData) {
    const { fund } = fundData
    return (
      <>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to='/ngos'>{t('breadcrumbs.NGOs')}</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to={`/ngos/${fund.internal_id}`}>
              {t('breadcrumbs.NGO')} — {fund.name}
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>{t('breadcrumbs.fundraiserNew')}</Breadcrumb.Item>
        </Breadcrumb>
        <div className='site-layout-content'>
          <div className='campaign-creation-wrapper'>
            <CampaignNewForm fundId={String(fund.internal_id)} curator={fund.authorized_person} />
          </div>
        </div>
      </>
    )
  }

  return <ErrorFeedback />
}
