import { CheckOutlined, CloseCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { Button, Col, Divider, Form, Input, notification, Typography } from 'antd'
import { useEffect } from 'react'

import { t } from 'languages'
import { I_SendEveryone } from 'models/notifications'
import { notificationsAPI } from 'services/notifications'

export const SendEveryoneBlock = () => {
  const [form] = Form.useForm<I_SendEveryone>()

  const [fetchSendNotification, { isSuccess, isLoading, isError, error }] =
    notificationsAPI.useSendPushEveryoneMutation()

  const handleFinish = (values: I_SendEveryone) => {
    fetchSendNotification(values)
  }

  // Отправка уведомления прошла успешно
  useEffect(() => {
    if (isSuccess) {
      notification.open({
        message: t('notificationsPage.blocks.sendEveryone.notifications.sendSuccess'),
        icon: <CheckOutlined style={{ color: '#52c41a' }} />,
      })
    }
  }, [isSuccess])

  // Отправка уведомления прошла с ошибкой
  useEffect(() => {
    if (isError) {
      notification.open({
        message: t('notificationsPage.blocks.sendEveryone.notifications.sendError'),
        description: (error as any)?.data?.message,
        icon: <CloseCircleOutlined style={{ color: '#c4391a' }} />,
      })
    }
  }, [isError, error])

  return (
    <Col xs={24} lg={12} xl={8} className='notifications-block'>
      <Divider orientation='left' orientationMargin='0'>
        <Typography.Title level={3}>
          {t('notificationsPage.blocks.sendEveryone.title')}
        </Typography.Title>
      </Divider>
      <Form form={form} layout='vertical' autoComplete='off' onFinish={handleFinish}>
        <Form.Item
          name='title'
          label={t('notificationsPage.blocks.sendEveryone.form.title')}
          rules={[{ required: true }]}
        >
          <Input showCount maxLength={30} />
        </Form.Item>
        <Form.Item
          name='text'
          label={t('notificationsPage.blocks.sendEveryone.form.text')}
          rules={[{ required: true }]}
        >
          <Input.TextArea showCount maxLength={240} rows={4} />
        </Form.Item>
        <Form.Item>
          <Button disabled={isLoading} size='large' type='primary' htmlType='submit'>
            {t('notificationsPage.blocks.sendEveryone.form.actions.send')}
          </Button>
        </Form.Item>
      </Form>
    </Col>
  )
}
