import { I_Locations } from 'models/dictionaries'

export const getCountries = (locations: I_Locations) =>
  locations.subjects.map((subject) => ({ id: subject.id, name: subject.name }))

export const getStates = (locations: I_Locations) =>
  locations.subjects[0].parentObjects
    .map((subject) => ({
      id: subject.id,
      name: subject.name,
    }))
    .slice(1)
