import { Card, Col, Divider, Row, Tag, Typography } from 'antd'

import './styles.scss'

import { t } from 'languages'
import { I_FinanceStatistics } from 'models/statistics'
import { numberWithSpaces } from 'utils/helpers/text'

interface I_FinanceProps {
  financeStatistics: I_FinanceStatistics
}

export const Finance = ({ financeStatistics }: I_FinanceProps) => {
  const {
    target_finance_opened_campaigns,
    number_of_opened_funds,
    number_of_opened_campaigns,
    collected_finance_opened_campaigns,
    number_of_funds,
    number_of_campaigns,
    all_collected_finance,
    reported_finance,
    reported_funds,
    reported_campaigns,
    delayed_report_finance,
    delayed_report_funds,
    delayed_report_campaigns,
    need_report_finance,
    need_report_funds,
    need_report_campaigns,
  } = financeStatistics
  return (
    <>
      <Divider orientation='left' orientationMargin='0'>
        <Typography.Title level={3}>{t('stats.finance')}</Typography.Title>
      </Divider>
      <Row gutter={[16, 16]}>
        <Col xs={24} lg={12} xl={8}>
          <Card>
            <div className='finance-list'>
              <div className='finance-item'>
                <span>{t('stats.totalAmountOfAllOpenedGoals')}</span>
                <Tag color='#108ee9'>
                  {numberWithSpaces(String(target_finance_opened_campaigns))}
                </Tag>
              </div>
              <div className='finance-item'>
                <span>{t('stats.byNGOs')}</span>
                <Tag color='#87d068'>{number_of_opened_funds}</Tag>
              </div>
              <div className='finance-item'>
                <span>{t('stats.byFundraisers')}</span>
                <Tag color='#fc953b'>{number_of_opened_campaigns}</Tag>
              </div>
            </div>
          </Card>
        </Col>
        <Col xs={24} lg={12} xl={8}>
          <Card>
            <div className='finance-list'>
              <div className='finance-item'>
                <span>{t('stats.totalRaisedOfAllOpenedFundraisers')}</span>
                <Tag color='#108ee9'>
                  {numberWithSpaces(String(collected_finance_opened_campaigns))}
                </Tag>
              </div>
              <div className='finance-item'>
                <span>{t('stats.byNGOs')}</span>
                <Tag color='#87d068'>{number_of_opened_funds}</Tag>
              </div>
              <div className='finance-item'>
                <span>{t('stats.byFundraisers')}</span>
                <Tag color='#fc953b'>{number_of_opened_campaigns}</Tag>
              </div>
            </div>
          </Card>
        </Col>
        <Col xs={24} lg={12} xl={8}>
          <Card>
            <div className='finance-list'>
              <div className='finance-item'>
                <span>{t('stats.totalAmountRaisedByAllTime')}</span>
                <Tag color='#108ee9'>{numberWithSpaces(String(all_collected_finance))}</Tag>
              </div>
              <div className='finance-item'>
                <span>{t('stats.byNGOs')}</span> <Tag color='#87d068'>{number_of_funds}</Tag>
              </div>
              <div className='finance-item'>
                <span>{t('stats.byFundraisers')}</span>
                <Tag color='#fc953b'>{number_of_campaigns}</Tag>
              </div>
            </div>
          </Card>
        </Col>
        <Col xs={24} lg={12} xl={8}>
          <Card>
            <div className='finance-list'>
              <div className='finance-item'>
                <span>{t('stats.reportedByAllTime')}</span>
                <Tag color='#108ee9'>{numberWithSpaces(String(reported_finance))}</Tag>
              </div>
              <div className='finance-item'>
                <span>{t('stats.byNGOs')}</span>
                <Tag color='#87d068'>{reported_funds}</Tag>
              </div>
              <div className='finance-item'>
                <span>{t('stats.byFundraisers')}</span>
                <Tag color='#fc953b'>{reported_campaigns}</Tag>
              </div>
            </div>
          </Card>
        </Col>
        <Col xs={24} lg={12} xl={8}>
          <Card>
            <div className='finance-list'>
              <div className='finance-item'>
                <span>{t('stats.delayedReportFinance')}</span>
                <Tag color='#108ee9'>{numberWithSpaces(String(delayed_report_finance))}</Tag>
              </div>
              <div className='finance-item'>
                <span>{t('stats.byNGOs')}</span>
                <Tag color='#87d068'>{delayed_report_funds}</Tag>
              </div>
              <div className='finance-item'>
                <span>{t('stats.byFundraisers')}</span>
                <Tag color='#fc953b'>{delayed_report_campaigns}</Tag>
              </div>
            </div>
          </Card>
        </Col>
        <Col xs={24} lg={12} xl={8}>
          <Card>
            <div className='finance-list'>
              <div className='finance-item'>
                <span>{t('stats.needReportFinance')}</span>
                <Tag color='#108ee9'>{numberWithSpaces(String(need_report_finance))}</Tag>
              </div>
              <div className='finance-item'>
                <span>{t('stats.byNGOs')}</span>
                <Tag color='#87d068'>{need_report_funds}</Tag>
              </div>
              <div className='finance-item'>
                <span>{t('stats.byFundraisers')}</span>
                <Tag color='#fc953b'>{need_report_campaigns}</Tag>
              </div>
            </div>
          </Card>
        </Col>
      </Row>
    </>
  )
}
