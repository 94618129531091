import { Form, Input, Select, DatePicker, Row, Col, Upload, Modal } from 'antd'
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface'
import { useEffect, useState } from 'react'

import { UploadButton } from 'components/UploadButton'
import { ErrorFeedback } from 'components/Utilities/ErrorFeedback'
import { Loader } from 'components/Utilities/Loader'
import { dictionariesAPI } from 'services/dictionaries'
import { filesAPI } from 'services/files'
import { getCountries, getStates } from 'utils/dictionaries/locations'
import {
  beforeImageUpload,
  customRequestUpload,
  getBase64,
  normalizeImages,
  uploadImageAcceptFiles,
  uploadImageExtraText,
} from 'utils/forms/fileUploader'
import { E_FormatDate } from 'utils/helpers/date'

import type { I_FundForm } from 'models/fund'

interface GeneralInfoProps {
  withStatus?: boolean
  disabled?: boolean
  fileList?: any
}
export const GeneralInfo = ({ withStatus = false, disabled, fileList }: GeneralInfoProps) => {
  const { data: fundStatuses, isLoading: isFundStatusesLoading } =
    dictionariesAPI.useGetFundStatusesQuery()
  const { data: locations, isLoading: isLocationsLoading } = dictionariesAPI.useGetLocationsQuery()
  const { data: fundSizes, isLoading: isSizesLoading } = dictionariesAPI.useGetFundSizesQuery()
  const [fetchUpload] = filesAPI.useUploadPublicFileMutation()

  const [uploadProcess, setUploadProcess] = useState({
    previewVisible: false,
    previewImage: '',
    previewTitle: '',
    fileList: fileList || [],
  })

  const handlePreview = async (file: any) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }
    setUploadProcess((prev) => ({
      ...prev,
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
    }))
  }

  const handleClosePreview = () => setUploadProcess((prev) => ({ ...prev, previewVisible: false }))

  const handleChangePhoto = (info: UploadChangeParam<UploadFile<any>>) => {
    setUploadProcess((prev) => ({ ...prev, fileList: info.fileList }))
  }

  useEffect(() => {
    if (uploadProcess.fileList.length || fileList) {
      setUploadProcess((prev) => ({ ...prev, fileList }))
    }
  }, [fileList])

  if (isFundStatusesLoading || isLocationsLoading || isSizesLoading) return <Loader relative />

  if (fundStatuses && locations && fundSizes) {
    const countries = getCountries(locations)
    const states = getStates(locations)
    const sizes = fundSizes.sizes

    return (
      <>
        {withStatus && (
          <>
            <Form.Item name='status_id' label='Status' rules={[{ required: true }]}>
              <Select disabled={disabled}>
                {fundStatuses.statuses.map((status) => (
                  <Select.Option key={status.id} value={status.id}>
                    {status.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues: I_FundForm, currentValues: I_FundForm) =>
                prevValues.status_id !== currentValues.status_id
              }
            >
              {({ getFieldValue }: any) =>
                getFieldValue('status_id') === 'suspended' ||
                getFieldValue('status_id') === 'blocked' ? (
                  <Form.Item name='reason_for_blocking' label='Reason' rules={[{ required: true }]}>
                    <Input disabled={disabled} />
                  </Form.Item>
                ) : null
              }
            </Form.Item>
          </>
        )}
        <Row>
          <Col span={10}>
            <Form.Item name='name' label='Name' rules={[{ required: true }]}>
              <Input disabled={disabled} />
            </Form.Item>
          </Col>
          <Col span={6} offset={1}>
            <Form.Item name='size' label='Fund size'>
              <Select disabled={disabled}>
                {sizes.map((size) => (
                  <Select.Option key={size.id} value={size.id}>
                    {size.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={6} offset={1}>
            <Form.Item name='founding_date' label='Founding date' rules={[{ required: true }]}>
              <DatePicker
                disabled={disabled}
                style={{ width: '100%' }}
                format={E_FormatDate.shortYear}
                placeholder='Select date'
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={10}>
            <Form.Item
              name={['actual_address', 'country']}
              label='Country'
              rules={[{ required: true }]}
            >
              <Select allowClear disabled={disabled}>
                {countries.map((country) => (
                  <Select.Option key={country.id} value={country.id}>
                    {country.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={13} offset={1}>
            <Form.Item
              name={['actual_address', 'state']}
              label='State'
              rules={[{ required: true }]}
            >
              <Select allowClear disabled={disabled}>
                {states.map((state) => (
                  <Select.Option key={state.id} value={state.id}>
                    {state.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={10}>
            <Form.Item name={['actual_address', 'city']} label='City' rules={[{ required: true }]}>
              <Input disabled={disabled} />
            </Form.Item>
          </Col>
          <Col span={13} offset={1}>
            <Form.Item
              name={['actual_address', 'address']}
              label='Central office address'
              rules={[{ required: true }]}
            >
              <Input disabled={disabled} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <Form.Item name='website' label='Website' rules={[{ required: true }]}>
              <Input placeholder='some-thing.com' disabled={disabled} />
            </Form.Item>
          </Col>
          <Col span={8} offset={1}>
            <Form.Item name='email' label='Email' rules={[{ required: true, type: 'email' }]}>
              <Input placeholder='some-thing@gmail.com' disabled={disabled} />
            </Form.Item>
          </Col>
          <Col span={6} offset={1}>
            <Form.Item name='phone' label='Phone number' rules={[{ required: true }]}>
              <Input style={{ width: '100%' }} disabled={disabled} />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          name='carousel_photos'
          label='Carousel photos'
          valuePropName='fileList'
          getValueFromEvent={normalizeImages}
          rules={[{ required: true }]}
          extra={uploadImageExtraText}
        >
          <Upload
            accept={uploadImageAcceptFiles}
            listType='picture-card'
            onPreview={handlePreview}
            customRequest={(options) => customRequestUpload(options, fetchUpload)}
            onChange={handleChangePhoto}
            beforeUpload={beforeImageUpload}
            disabled={disabled}
          >
            {uploadProcess.fileList.length >= 8 ? null : <UploadButton />}
          </Upload>
        </Form.Item>
        <Modal
          visible={uploadProcess.previewVisible}
          title={uploadProcess.previewTitle}
          footer={null}
          onCancel={handleClosePreview}
        >
          <img
            alt='preview main photo'
            style={{ width: '100%' }}
            src={uploadProcess.previewImage}
          />
        </Modal>
      </>
    )
  }
  return <ErrorFeedback relative />
}
