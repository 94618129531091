import { Steps } from 'antd'
import { RefObject, useState } from 'react'

import { useScrollSteps } from './extension/useScrollSteps'

import { t } from 'languages'
import { T_Step } from 'pages/FundsNew/data'

interface NavigationStepsProps {
  wrapperRef: RefObject<HTMLDivElement>
  steps: T_Step[]
  stepsStatus: boolean[]
}

export const NavigationSteps = ({ wrapperRef, steps, stepsStatus }: NavigationStepsProps) => {
  const [currentStep, setCurrentStep] = useState(0)
  const [isHandled, setIsHandled] = useState(false)

  useScrollSteps(wrapperRef, setCurrentStep, isHandled)

  // Изменение шага по нажатию
  const handleChangeSteps = (value: number) => {
    setIsHandled(true)
    setCurrentStep(value)
    const elem = document.getElementById(String(value))
    elem && elem.scrollIntoView({ behavior: 'smooth' })
    setTimeout(() => setIsHandled(false), 550)
  }

  return (
    <Steps direction='vertical' size='small'>
      {steps.map((step, index) => (
        <Steps.Step
          key={step.title}
          title={t(step.title)}
          icon={step.icon}
          onClick={() => handleChangeSteps(index)}
          style={{ cursor: 'pointer' }}
          status={currentStep < index ? 'wait' : stepsStatus[index] ? 'process' : 'error'}
        />
      ))}
    </Steps>
  )
}
