import { Space } from 'antd'
import moment from 'moment'

import { t } from 'languages'
import { I_ReportRecord } from 'models/report'
import { E_FormatDate } from 'utils/helpers/date'

export const getColumns = () => [
  {
    title: t('dataReports.table.id'),
    dataIndex: 'report_internal_id',
    sorter: (a: I_ReportRecord, b: I_ReportRecord) => a.report_internal_id - b.report_internal_id,
  },
  {
    title: t('dataReports.table.created'),
    dataIndex: 'created_at',
    sorter: (a: I_ReportRecord, b: I_ReportRecord) =>
      moment(a.created_at).unix() - moment(b.created_at).unix(),
    render: (created_at: string) => (
      <Space size='middle'>{moment(created_at).format(E_FormatDate.default)}</Space>
    ),
  },
  {
    title: t('dataReports.table.updated'),
    dataIndex: 'updated_date',
    sorter: (a: I_ReportRecord, b: I_ReportRecord) =>
      moment(a.updated_date).unix() - moment(b.updated_date).unix(),
    render: (_: string, record: I_ReportRecord) => (
      <Space size='middle'>{moment(record.updated_date).format(E_FormatDate.default)}</Space>
    ),
  },
  {
    title: t('dataReports.table.rating'),
    dataIndex: ['rating', 'rating'],
    sorter: (a: I_ReportRecord, b: I_ReportRecord) => a.rating.rating - b.rating.rating,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    render: (value: any) => <Space size='middle'>{value ? value : '-'}</Space>,
  },

  {
    title: t('dataReports.table.createdBy'),
    dataIndex: ['isCreatedAutomatically'],
    sorter: (a: I_ReportRecord) => Number(a.isCreatedAutomatically),
    render: (_: string, record: I_ReportRecord) => (
      <Space>{record.isCreatedAutomatically ? 'Auto' : 'Manual'}</Space>
    ),
  },
]
