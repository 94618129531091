import moment from 'moment'

import { I_Campaign, I_CampaignForm } from 'models/campaign'
import { T_Authorized_person } from 'models/fund'

export const fundraiserToForm = (fundraiser: I_Campaign): I_CampaignForm => {
  const { main_photo, closed_at, ...fields } = fundraiser

  return {
    ...fields,
    main_photo: [{ uid: '1', url: main_photo.url }],
    documents: fundraiser.documents.map((document, index) => ({ ...document, uid: String(index) })),
    closed_at: moment(closed_at),
  }
}

export const formToFundraiser = (
  form: I_CampaignForm,
  close_by_time: boolean,
  curator: T_Authorized_person,
): I_Campaign => {
  const { main_photo, ...fields } = form
  return {
    ...fields,
    curator,
    main_photo: { url: main_photo[0].url },
    close_by_time: close_by_time,
  }
}
