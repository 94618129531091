/* eslint-disable @typescript-eslint/no-explicit-any */
import { DeleteOutlined } from '@ant-design/icons'
import { Button, Space, Tag, Tooltip } from 'antd'
import moment from 'moment'

import { t } from 'languages'
import { I_CampaignReviewRecord } from 'models/review'
import { E_FormatDate } from 'utils/helpers/date'

export const getColumns = (handleRemove: (id: string) => void) => [
  {
    title: t('fundraiser.table.columns.id'),
    dataIndex: 'review_internal_id',
    sorter: (a: I_CampaignReviewRecord, b: I_CampaignReviewRecord) =>
      a.review_internal_id - b.review_internal_id,
  },
  {
    title: t('fundraiser.table.columns.overall'),
    dataIndex: 'overall_rating',
    sorter: (a: I_CampaignReviewRecord, b: I_CampaignReviewRecord) =>
      a.overall_rating - b.overall_rating,
    render: (_: any, record: I_CampaignReviewRecord) => (
      <Tag color='processing'>{record.overall_rating}</Tag>
    ),
  },
  {
    title: t('fundraiser.table.columns.relevance'),
    dataIndex: 'relevance',
    sorter: (a: I_CampaignReviewRecord, b: I_CampaignReviewRecord) => a.relevance - b.relevance,
  },
  {
    title: t('fundraiser.table.columns.feedback'),
    dataIndex: 'feedback',
    sorter: (a: I_CampaignReviewRecord, b: I_CampaignReviewRecord) => a.feedback - b.feedback,
  },
  {
    title: t('fundraiser.table.columns.completeness'),
    dataIndex: 'completeness_of_information',
    sorter: (a: I_CampaignReviewRecord, b: I_CampaignReviewRecord) =>
      a.completeness_of_information - b.completeness_of_information,
  },
  {
    title: t('fundraiser.table.columns.trust'),
    dataIndex: 'trust',
    sorter: (a: I_CampaignReviewRecord, b: I_CampaignReviewRecord) =>
      Number(a.trust) - Number(b.trust),
  },
  {
    title: t('fundraiser.table.columns.date'),
    dataIndex: 'created_at',
    sorter: (a: I_CampaignReviewRecord, b: I_CampaignReviewRecord) =>
      moment(a.created_at).unix() - moment(b.created_at).unix(),
    render: (created_at: string) => (
      <Space size='middle'>{moment(created_at).format(E_FormatDate.default)}</Space>
    ),
  },
  {
    key: 'action',
    render: (record: I_CampaignReviewRecord) => (
      <Space size='middle'>
        <Tooltip title='Удалить'>
          <Button
            icon={<DeleteOutlined />}
            onClick={() => handleRemove(String(record.review_internal_id))}
          />
        </Tooltip>
      </Space>
    ),
  },
]
