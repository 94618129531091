import { DeleteOutlined, CheckOutlined, EyeOutlined } from '@ant-design/icons'
import { Button, Space, Tooltip } from 'antd'
import moment from 'moment'
import { Link } from 'react-router-dom'

import { t } from 'languages'
import { T_ReportId } from 'models/report'
import { I_ReportReviewRecord, T_ReportReviewId } from 'models/review'
import { E_FormatDate } from 'utils/helpers/date'

export const getColumns = (
  handleApprove: (reviewId: T_ReportReviewId, reportId: T_ReportId) => void,
  handleRemove: (reviewId: T_ReportReviewId) => void,
) => [
  {
    title: t('complaintsReports.table.id'),
    dataIndex: 'review_internal_id',
    sorter: (a: I_ReportReviewRecord, b: I_ReportReviewRecord) =>
      a.review_internal_id - b.review_internal_id,
  },
  {
    title: t('complaintsReports.table.reportId'),
    dataIndex: 'report_internal_id',
    sorter: (a: I_ReportReviewRecord, b: I_ReportReviewRecord) =>
      a.report_internal_id - b.report_internal_id,
  },
  {
    title: t('complaintsReports.table.complaintCount'),
    dataIndex: 'complaintCount',
    sorter: (a: I_ReportReviewRecord, b: I_ReportReviewRecord) =>
      a.complaintCount - b.complaintCount,
  },
  {
    title: t('complaintsReports.table.overall'),
    dataIndex: 'overall_rating',
    sorter: (a: I_ReportReviewRecord, b: I_ReportReviewRecord) =>
      a.overall_rating - b.overall_rating,
  },
  {
    title: t('complaintsReports.table.persuasiveness'),
    dataIndex: 'persuasiveness_of_evidence',
    sorter: (a: I_ReportReviewRecord, b: I_ReportReviewRecord) =>
      a.persuasiveness_of_evidence - b.persuasiveness_of_evidence,
  },
  {
    title: t('complaintsReports.table.compliance'),
    dataIndex: 'compliance_with_goals',
    sorter: (a: I_ReportReviewRecord, b: I_ReportReviewRecord) =>
      a.compliance_with_goals - b.compliance_with_goals,
  },
  {
    title: t('complaintsReports.table.clarity'),
    dataIndex: 'clarity',
    sorter: (a: I_ReportReviewRecord, b: I_ReportReviewRecord) => a.clarity - b.clarity,
  },
  {
    title: t('complaintsReports.table.trust'),
    dataIndex: 'trust',
    sorter: (a: I_ReportReviewRecord, b: I_ReportReviewRecord) => a.trust - b.trust,
  },
  {
    title: t('complaintsReports.table.date'),
    dataIndex: 'created_at',
    sorter: (a: I_ReportReviewRecord, b: I_ReportReviewRecord) =>
      moment(a.created_at).unix() - moment(b.created_at).unix(),
    render: (created_at: string) => (
      <Space size='middle'>{moment(created_at).format(E_FormatDate.default)}</Space>
    ),
  },
  {
    key: 'action',
    render: (record: I_ReportReviewRecord) => (
      <Space size='middle'>
        <Tooltip title={t('tooltip.delete')} placement='topLeft'>
          <Button
            icon={<DeleteOutlined />}
            onClick={() => handleRemove(String(record.review_internal_id))}
          />
        </Tooltip>
        <Tooltip title={t('tooltip.approve')} placement='topLeft'>
          <Button
            icon={<CheckOutlined />}
            onClick={() =>
              handleApprove(String(record.review_internal_id), String(record.report_internal_id))
            }
          />
        </Tooltip>
        <Tooltip title={t('tooltip.toReport')} placement='topLeft'>
          <Link
            to={`/ngos/${String(record.fund_internal_id)}/fundraiser/${String(
              record.campaign_internal_id,
            )}/report`}
            target='_blank'
          >
            <Button icon={<EyeOutlined />} />
          </Link>
        </Tooltip>
      </Space>
    ),
  },
]
