import { createApi } from '@reduxjs/toolkit/query/react'

import { baseQueryWithReAuth } from '../utils'

import { I_CommentResponse } from 'models/fund'
import { I_Report, I_ReportResponse, T_ReportId, T_ReportInternalId } from 'models/report'
import { I_ReportReviewResponse, T_ReportReviewId } from 'models/review'

export const reportsAPI = createApi({
  reducerPath: 'reportsAPI',
  baseQuery: baseQueryWithReAuth,
  tagTypes: ['report', 'reportReviews', 'reportsReviews'],
  endpoints: (build) => ({
    getReports: build.query<{ reposts: I_ReportResponse[] }, void>({
      query: () => ({
        url: `/reports`,
      }),
      providesTags: ['report'],
    }),

    getReport: build.query<{ report: I_ReportResponse }, string>({
      query: (campaignId) => ({
        url: `/campaigns/${campaignId}/report`,
      }),
      providesTags: ['report'],
    }),

    updateReport: build.mutation<I_ReportResponse, { report: I_Report; campaignId: string }>({
      query: (payload) => ({
        url: `/campaigns/${payload.campaignId}/report`,
        method: 'PUT',
        body: payload.report,
      }),
      invalidatesTags: ['report'],
    }),

    updateReportComment: build.mutation<
      I_CommentResponse,
      { comment: string; reportId: T_ReportId }
    >({
      query: (payload) => ({
        url: `/report/${payload.reportId}/comment`,
        method: 'PUT',
        body: { comment: payload.comment },
      }),
      invalidatesTags: ['report'],
    }),

    getReportReviews: build.query<
      { review: I_ReportReviewResponse[]; reviewId: T_ReportReviewId },
      T_ReportId
    >({
      query: (reportId) => ({
        url: `/reports/${reportId}/reviews`,
      }),
      providesTags: ['reportReviews'],
    }),

    getReportsReviews: build.query<{ reviews: I_ReportReviewResponse[] }, void>({
      query: () => ({
        url: `/reports/reviews`,
      }),
      providesTags: ['reportsReviews'],
    }),

    deleteReportReview: build.mutation<void, T_ReportReviewId>({
      query: (reviewId) => ({
        url: `/reports/reviews/${reviewId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['report', 'reportReviews', 'reportsReviews'],
    }),

    approveReportReviewComplaint: build.mutation<
      void,
      { reviewId: T_ReportReviewId; reportId: T_ReportId }
    >({
      query: (payload) => ({
        url: `/reports/${payload.reportId}/${payload.reviewId}/complaint`,
        method: 'PUT',
      }),
      invalidatesTags: ['reportsReviews'],
    }),

    sendReportNotification: build.query<void, T_ReportInternalId>({
      query: (reportId) => ({
        url: `/report/${String(reportId)}/send`,
      }),
      providesTags: ['reportsReviews'],
    }),
  }),
})
