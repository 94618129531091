import { Breadcrumb } from 'antd'
import { FieldError } from 'rc-field-form/es/interface'
import { useRef, useState } from 'react'
import { Link } from 'react-router-dom'

import './styles.scss'
import { initialStepsStatus, steps } from './data'
import { errorsToStepsStatus } from './utils'

import { FundNewForm } from 'components/Forms'
import { NavigationSteps } from 'components/NavigationSteps'
import { t } from 'languages'

export const FundsNewPage = () => {
  const wrapperRef = useRef<HTMLDivElement>(null)
  const [stepsStatus, setStepsStatus] = useState<boolean[]>(initialStepsStatus)

  const handleErrorDetected = (errors: FieldError[]) => {
    setStepsStatus(errorsToStepsStatus(errors))
  }

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to='ngos'>{t('breadcrumbs.NGOs')}</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{t('breadcrumbs.NGOsNew')}</Breadcrumb.Item>
      </Breadcrumb>
      <div className='site-layout-content'>
        <div className='fund-creation-wrapper'>
          <div className='fund-creation-wrapper_steps'>
            <NavigationSteps steps={steps} stepsStatus={stepsStatus} wrapperRef={wrapperRef} />
          </div>
          <div className='fund-creation-wrapper_form'>
            <FundNewForm
              ref={wrapperRef}
              onErrorDetected={(errors) => handleErrorDetected(errors)}
            />
          </div>
        </div>
      </div>
    </>
  )
}
