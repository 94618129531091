import { Outlet } from 'react-router-dom'

import { useStoreSelector } from 'hooks/useStoreSelector'
import { AuthLayout } from 'layouts/Auth'
import { DashboardLayout } from 'layouts/Dashboard'
import { LocalStorage } from 'utils/helpers/localStorage'

export const ProtectedRoute = () => {
  const token = LocalStorage.getUser()
  const isAuth = useStoreSelector((state) => state.profile.isAuth)

  if (token && isAuth) {
    return (
      <DashboardLayout>
        <Outlet />
      </DashboardLayout>
    )
  }

  return <AuthLayout />
}
