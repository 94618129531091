import { ExclamationCircleOutlined, CheckOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { Table, Modal, notification } from 'antd'
import { useEffect } from 'react'

import { getColumns } from './data'

import { ErrorFeedback } from 'components/Utilities/ErrorFeedback'
import { Loader } from 'components/Utilities/Loader'
import { t } from 'languages'
import { T_ReportInternalId } from 'models/report'
import { T_ReportReviewId } from 'models/review'
import { reportsAPI } from 'services/reports'
import { formatToDataSource } from 'utils/helpers/table'

interface TableReviewsProps {
  reportId: T_ReportInternalId
}

export const ReviewsTable = ({ reportId }: TableReviewsProps) => {
  const { data: reviewsData, isLoading: isReviewsLoading } = reportsAPI.useGetReportReviewsQuery(
    String(reportId),
  )

  const [fetchRemoveReview, { isSuccess, isError, error }] =
    reportsAPI.useDeleteReportReviewMutation()

  const handleRemove = (id: T_ReportReviewId) => {
    Modal.confirm({
      title: t('modal.confirm.removeComment.title'),
      icon: <ExclamationCircleOutlined />,
      content: t('modal.confirm.removeComment.content'),
      okText: t('modal.confirm.removeComment.ok'),
      cancelText: t('modal.confirm.removeComment.cancel'),
      maskClosable: true,
      onOk: () => {
        fetchRemoveReview(id)
      },
    })
  }

  // Удаление обзора прошло успешно
  useEffect(() => {
    if (isSuccess) {
      notification.open({
        message: t('notifications.reviews.successDeleted'),
        icon: <CheckOutlined style={{ color: '#52c41a' }} />,
      })
    }
  }, [isSuccess])

  // Удаление обзора произошло с ошибкой
  useEffect(() => {
    if (isError) {
      notification.open({
        message: t('notifications.reviews.errorDeleted'),
        description: (error as any)?.data?.message,
        icon: <CloseCircleOutlined style={{ color: '#c4391a' }} />,
      })
    }
  }, [isError, error])

  if (isReviewsLoading) return <Loader relative />

  if (reviewsData) {
    const dataTable = formatToDataSource(reviewsData.review)
    return (
      <Table
        columns={getColumns(handleRemove)}
        dataSource={dataTable}
        expandable={{
          expandedRowRender: (record) => <p style={{ margin: 0 }}>{record.text}</p>,
        }}
      />
    )
  }

  return <ErrorFeedback relative />
}
