import { combineReducers, configureStore } from '@reduxjs/toolkit'
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import profileSlice from './profile'
import uiSlice from './ui'

import { authAPI } from 'services/auth'
import { campaignsAPI } from 'services/campaigns'
import { dictionariesAPI } from 'services/dictionaries'
import { filesAPI } from 'services/files'
import { fundsAPI } from 'services/funds'
import { notificationsAPI } from 'services/notifications'
import { refundAPI } from 'services/refund'
import { reportsAPI } from 'services/reports'
import { statisticsAPI } from 'services/statistics'

const persistConfig = {
  key: 'cherrity',
  storage,
  whitelist: [profileSlice.name, uiSlice.name],
}

const rootReducer = combineReducers({
  // Global slices
  [profileSlice.name]: profileSlice.reducer,
  [uiSlice.name]: uiSlice.reducer,

  // Services
  [campaignsAPI.reducerPath]: campaignsAPI.reducer,
  [authAPI.reducerPath]: authAPI.reducer,
  [fundsAPI.reducerPath]: fundsAPI.reducer,
  [dictionariesAPI.reducerPath]: dictionariesAPI.reducer,
  [filesAPI.reducerPath]: filesAPI.reducer,
  [reportsAPI.reducerPath]: reportsAPI.reducer,
  [statisticsAPI.reducerPath]: statisticsAPI.reducer,
  [notificationsAPI.reducerPath]: notificationsAPI.reducer,
  [refundAPI.reducerPath]: refundAPI.reducer,
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat([
      authAPI.middleware,
      campaignsAPI.middleware,
      fundsAPI.middleware,
      dictionariesAPI.middleware,
      filesAPI.middleware,
      reportsAPI.middleware,
      statisticsAPI.middleware,
      notificationsAPI.middleware,
      refundAPI.middleware,
    ]),
})

export const persistor = persistStore(store)

export type RootState = ReturnType<typeof store.getState>
export type RootDispatch = typeof store.dispatch
