import { createSlice } from '@reduxjs/toolkit'

interface I_UI {
  sidebarCollapsed: boolean
}

const initialState: I_UI = {
  sidebarCollapsed: false,
}

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    toggleSidebar: (state) => {
      state.sidebarCollapsed = !state.sidebarCollapsed
    },
  },
})

export const { toggleSidebar } = uiSlice.actions

export default uiSlice
