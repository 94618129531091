import { CheckOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { Button, Col, Descriptions, Divider, Form, Input, notification, Typography } from 'antd'
import { useEffect } from 'react'

import { Brick } from 'components/Utilities/Brick'
import { t } from 'languages'
import { refundAPI } from 'services/refund'
import { numberWithSpaces } from 'utils/helpers/text'

export const ReturnTransactionBlock = () => {
  const [form] = Form.useForm<{ donationId: string }>()

  // Получение транзакции
  const [
    fetchGetDonation,
    {
      data: donationData,
      isLoading: isDonationLoading,
      isSuccess: isDonationSuccess,
      isError: isDonationError,
      error: donationError,
    },
  ] = refundAPI.useLazyGetDonationQuery()

  const [
    fetchReturnDonation,
    {
      isLoading: isDonationReturnLoading,
      isSuccess: isDonationReturnSuccess,
      isError: isDonationReturnError,
      error: donationReturnError,
    },
  ] = refundAPI.useLazyReturnDonationQuery()

  const handleFinish = (values: { donationId: string }) => {
    fetchGetDonation(values.donationId)
  }

  const handleReturnDonation = () => {
    if (donationData) {
      fetchReturnDonation(donationData.donation.id)
    }
  }

  // Получение пожертвования прошло успешно
  useEffect(() => {
    if (isDonationSuccess) {
      notification.open({
        message: t('refundPage.blocks.returnTransaction.notifications.getSuccess'),
        icon: <CheckOutlined style={{ color: '#52c41a' }} />,
      })
    }
  }, [isDonationSuccess])

  // Получение пожертвования прошло с ошибкой
  useEffect(() => {
    if (isDonationError) {
      notification.open({
        message: t('refundPage.blocks.returnTransaction.notifications.getError'),
        description: (donationError as any)?.data?.message,
        icon: <CloseCircleOutlined style={{ color: '#c4391a' }} />,
      })
    }
  }, [isDonationError, donationError])

  // Возврат пожертвования прошёл успешно
  useEffect(() => {
    if (isDonationReturnSuccess) {
      notification.open({
        message: t('refundPage.blocks.returnTransaction.notifications.getSuccess'),
        icon: <CheckOutlined style={{ color: '#52c41a' }} />,
      })
      if (donationData) {
        fetchGetDonation(donationData.donation.id)
      }
    }
  }, [isDonationReturnSuccess])

  // Возврат пожертвования прошёл успешно с ошибкой
  useEffect(() => {
    if (isDonationReturnError) {
      notification.open({
        message: t('refundPage.blocks.returnTransaction.notifications.getError'),
        description: (donationReturnError as any)?.data?.message,
        icon: <CloseCircleOutlined style={{ color: '#c4391a' }} />,
      })
    }
  }, [isDonationReturnError, donationReturnError])

  return (
    <Col xs={24} lg={12} xl={12} className='notifications-block'>
      <Divider orientation='left' orientationMargin='0'>
        <Typography.Title level={3}>
          {t('refundPage.blocks.returnTransaction.title')}
        </Typography.Title>
      </Divider>
      <Form form={form} layout='vertical' autoComplete='off' onFinish={handleFinish}>
        <Form.Item
          name='donationId'
          label={t('refundPage.blocks.returnTransaction.form.transactionId')}
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item>
          <Button disabled={isDonationLoading} size='large' type='primary' htmlType='submit'>
            {t('refundPage.blocks.returnTransaction.form.actions.find')}
          </Button>
        </Form.Item>
      </Form>

      <Divider />

      {donationData && (
        <>
          <Descriptions title='Donation' bordered>
            <Descriptions.Item span={24} label='ID'>
              {donationData.donation.id}
            </Descriptions.Item>
            <Descriptions.Item span={24} label='PayUID'>
              {donationData.donation.mihpay_id || '-'}
            </Descriptions.Item>
            <Descriptions.Item span={24} label='Name'>
              {donationData.donation.name}
            </Descriptions.Item>
            <Descriptions.Item span={24} label='Phone'>
              {donationData.donation.phone}
            </Descriptions.Item>
            <Descriptions.Item span={24} label='Status'>
              {donationData.donation.status}
            </Descriptions.Item>
            <Descriptions.Item span={24} label='Amount of donations'>
              {numberWithSpaces(String(donationData.donation.amount_of_donations))}₹
            </Descriptions.Item>
            <Descriptions.Item span={24} label='Total donation amount'>
              {numberWithSpaces(String(donationData.donation.total_donation_amount))}₹
            </Descriptions.Item>
          </Descriptions>
          <Brick />
          <Button
            size='large'
            type='primary'
            disabled={donationData.donation.status === 'returned' || isDonationReturnLoading}
            onClick={handleReturnDonation}
          >
            {donationData.donation.status === 'returned' ? 'Returned' : 'Return'}
          </Button>
        </>
      )}
    </Col>
  )
}
