import { Form, Input, Modal, Upload } from 'antd'
import { useEffect, useState } from 'react'

import { UploadButton, UploadFileButton } from 'components/UploadButton'
import { filesAPI } from 'services/files'
import {
  beforeDocumentUpload,
  beforeImageUpload,
  customRequestUpload,
  getBase64,
  normalizeFile,
  normalizeImages,
  uploadDocumentAcceptFiles,
  uploadDocumentExtraText,
  uploadImageAcceptFiles,
  uploadImageExtraText,
} from 'utils/forms/fileUploader'

interface MediaProps {
  fileList?: any
  disabled?: boolean
  docs?: {
    documents: any[]
  } | null
}

export const GeneralInfo = ({ fileList, disabled = false, docs }: MediaProps) => {
  const [fetchUpload] = filesAPI.useUploadPublicFileMutation()
  const [uploadProcess, setUploadProcess] = useState({
    previewVisible: false,
    previewImage: '',
    previewTitle: '',
    fileList: fileList || [],
  })

  const handlePreview = async (file: any) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }
    setUploadProcess((prev) => ({
      ...prev,
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
    }))
  }

  const handleClosePreview = () => {
    setUploadProcess((prev) => ({ ...prev, previewVisible: false }))
  }

  const handleChangePhoto = ({ fileList }: any) => {
    setUploadProcess((prev) => ({ ...prev, fileList }))
  }

  useEffect(() => {
    if (uploadProcess.fileList.length || fileList) {
      setUploadProcess((prev) => ({ ...prev, fileList }))
    }
  }, [fileList])

  return (
    <>
      <Form.Item name='text' label='Text' rules={[{ required: true }]}>
        <Input.TextArea disabled={disabled} showCount />
      </Form.Item>
      <Form.Item
        name='photos'
        label='Photos'
        valuePropName='fileList'
        getValueFromEvent={normalizeImages}
        rules={[{ required: true }]}
        extra={uploadImageExtraText}
      >
        <Upload
          accept={uploadImageAcceptFiles}
          disabled={disabled}
          listType='picture-card'
          onPreview={handlePreview}
          customRequest={(options) => customRequestUpload(options, fetchUpload)}
          onChange={handleChangePhoto}
          beforeUpload={beforeImageUpload}
        >
          {uploadProcess.fileList.length >= 8 ? null : <UploadButton />}
        </Upload>
      </Form.Item>
      <Modal
        visible={uploadProcess.previewVisible}
        title={uploadProcess.previewTitle}
        footer={null}
        onCancel={handleClosePreview}
      >
        <img
          alt='preview report photo'
          style={{ width: '100%' }}
          src={uploadProcess.previewImage}
        />
      </Modal>
      <Form.Item name={['video', 'url']} label='Video'>
        <Input disabled={disabled} />
      </Form.Item>
      <Form.Item
        name='documents'
        valuePropName='file'
        label='Documents'
        extra={uploadDocumentExtraText}
        getValueFromEvent={normalizeFile}
        style={{ width: '30%' }}
      >
        <Upload
          accept={uploadDocumentAcceptFiles}
          listType='text'
          disabled={disabled}
          defaultFileList={docs ? docs.documents : []}
          customRequest={(options) => customRequestUpload(options, fetchUpload)}
          beforeUpload={beforeDocumentUpload}
        >
          <UploadFileButton disabled={disabled} />
        </Upload>
      </Form.Item>
    </>
  )
}
